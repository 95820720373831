import React from 'react';
import { useNavigate } from 'react-router-dom';

// Component Bussiness
import CardDetails from 'components/BussinesShared/CardDetails';
import { redirect } from 'components/routes/config';

// Components Shared
import { Button, Flex } from 'components/Shared';

// Images
import { CalendarIcon, CryptoIcon } from 'Images';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RootProps {
}

const Root: React.FC<RootProps> = ({}) => {
  const navigate = useNavigate();

  const lendSections = [{
    title: 'Delegate Time',
    Icon: CalendarIcon,
    lines: [
      {
        label: 'From',
        value: '10/05/2022',
        withDivider: true,
      },
      {
        label: 'To',
        value: '10/07/2022',
        withDivider: true,
      },
      {
        label: 'Total rent time',
        value: '62 days',
        withDivider: true,
      },
    ],
  },
  {
    title: 'Rent price',
    Icon: CryptoIcon,
    lines: [
      {
        label: 'Daily price',
        boldValue: '3590000 ETH',
        value: ' (6 USD)',
        withDivider: true,
      },
      {
        label: 'Total rent time',
        value: '62 days',
        withDivider: true,
      },
    ],
  }];

  return (
    <Flex styles={{ padding: 40, gap: 50 }}>
      <Button onClick={() => navigate(redirect.testForm())}>
        Form Example
      </Button>

      <Button onClick={() => navigate(redirect.testRent())}>
        Test Rent
      </Button>

      <CardDetails
        sections={lendSections}
        total={{
          label: 'TOTAL',
          boldValue: '112434 ETH',
          value: '(18 USD)',
        }}
      />
    </Flex>
  );
};

export default Root;
