import { Colors } from 'styles';

const getStyles = (isMobile: boolean) =>  ({
  //Tabs
  tab: {
    minHeight: 70,
    minWidth: isMobile ? '80vw' : '100%',
    padding: 0,
    alignItems: isMobile ? 'flex-start' : 'center',
    color: isMobile ? Colors.blackLight : Colors.white,
  },
  activeTab: {
    background: isMobile ? Colors.violetExtraLight : undefined,
  },
  label: {
    paddingLeft: isMobile ? 40 : 0,
  },
});

export default getStyles;
