import React from 'react';

// Material
import { ButtonProps } from '@mui/material';

//Shared
import Button from '../../Shared/Button';

// Styles
import { notSelectedStyles, notSelectedTextStyles, selectedStyles, selectedTextStyles } from './styles';

interface CustomFilterProps extends ButtonProps {
  text?: string;
  selected?: boolean;
  children?: React.ReactNode;
}

const Filter: React.FC<CustomFilterProps> = ({
  selected,
  children,
  ...buttonProps
}) => (
  <Button 
    sx={selected ? selectedStyles : notSelectedStyles} 
    textProps={selected ? selectedTextStyles : notSelectedTextStyles}
    variant={selected ? 'contained' : 'outlined'}
    size="small"
    {...buttonProps} 
  >
    {children}
  </Button>
);

export default Filter;
