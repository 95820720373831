const BaseStyles: Styles = {
  root: {
    gap: 24,
  },
  //Containers
  mainBox: {
    alignItems: 'center',
    gap: 24,
  },
  container: {
    gap: 24,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flex: 1,
    width: '100%',
  },
  buttonContainer: {
    alignItems: 'flex-end',
    width: '100%',
  },
};
  
export default BaseStyles;
  