import { defaultContractConfig, wakeupContractConfig } from '../../config';

const Nft = {
  getList: {
    contractConfig: wakeupContractConfig,
    method: 'list',
  },

  tokenUri: {
    contractConfig: defaultContractConfig,
    method: 'tokenURI',
  },

  userOf: {
    contractConfig: defaultContractConfig,
    method: 'userOf',
  },

  'getPrice': {
    contractConfig: defaultContractConfig,
    method: 'getPrice',
  },

  'userExpires': {
    contractConfig: defaultContractConfig,
    method: 'userExpires',
  },

  'ownerOf': {
    contractConfig: defaultContractConfig,
    method: 'ownerOf',
  },

  'list': {
    contractConfig: wakeupContractConfig,
    method: 'list',
  },

  // Writes
  'mint': {
    contractConfig: defaultContractConfig,
    method: 'mint',
  },
  'setUser': {
    contractConfig: defaultContractConfig,
    method: 'setUser',
  },
  'rent': {
    contractConfig: wakeupContractConfig,
    method: 'rent',
  },
  'lend': {
    contractConfig: wakeupContractConfig,
    method: 'lend',
  },
  'approve': {
    contractConfig: defaultContractConfig,
    method: 'approve',
  },
  'cancelLend': {
    contractConfig: wakeupContractConfig,
    method: 'cancelLend',
  },
};

export default Nft;
