import React from 'react';
import { useParams } from 'react-router-dom';

//Date Utils
import { differenceInCalendarDays, differenceInSeconds } from 'date-fns/esm';
import { differenceInHours, differenceInMinutes } from 'date-fns';

// Bussiness Components
import { NftCardSimple, SectionHeader, CardDetails } from 'components/BussinesShared';
import { SelectOptions } from 'pages/Lend/Steps/CardTimeConfigurationDelegate/CardTimeConfigurationDelegate';

// Shared
import { Button, Flex } from 'components/Shared';

// Api
import { nftFake } from 'graphql/Nfts.mocks';

// Styles
import BaseStyles from './style';

//Crypto
import BigNumber from 'bignumber.js';

// Utils
import { EVM_CONSTANT } from 'utils/converter';
import { rentSections } from './utils';

//Mui
import { Alert } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';

//Constants
import { SECONDS_IN_24_HS } from 'utils/dateUtils';
import useDevice from 'hooks/useDevice';
import { useRent } from 'api/schema/nft';

interface SummaryProps {
  onClick: () => void;
  onBack: () => void;
  time: Date;
  timeType: SelectOptions;
}

const Summary: React.FC<SummaryProps> = ({
  onClick,
  onBack,
  time,
  timeType,
}) => {
  // Hooks
  const { isMobile } = useDevice();
  const { index, nftId, price } = useParams();
  const secondsToRent = differenceInSeconds(new Date(time), new Date());
  const rentCost = BigNumber(price || 0).times(secondsToRent).toFixed();
  const priceIn24Hs = BigNumber(price || 0).times(SECONDS_IN_24_HS).dividedBy(EVM_CONSTANT).toNumber();

  const { writeRent, loading, error, resetError } = useRent({
    index: index!,
    rentCost,
    secondsToRent,
    onComplete: onClick,
  });

  const getDifferenceInTimeType = ()=>
    timeType === SelectOptions.Days ? differenceInCalendarDays(new Date(time), new Date()) :
      timeType === SelectOptions.Hours ? differenceInHours(new Date(time), new Date()) + 1 :
        differenceInMinutes(new Date(time), new Date()) + 1;
    
  return (
    <Flex styles={BaseStyles.root}>
      <Flex styles={BaseStyles.mainBox}>
        <SectionHeader
          onBack={onBack}
          title="SUMMARY"
          callToActionProps={{
            text: 'Confirm',
            variant: 'contained',
            disabled: loading,
            loading: loading,
            onClick: () => {
              writeRent();
            },
          }}
        >
          <Flex styles={BaseStyles.container} row>
            {/* First Column */}
            <NftCardSimple
              id={nftId!}
              subtitle={nftFake.collection}
            />
        
            {/* Second Column */}
            <Flex styles={BaseStyles.container}>
              <CardDetails
                sections={rentSections(new Date(), new Date(time), getDifferenceInTimeType(), timeType, priceIn24Hs )}
                total={{
                  label: 'TOTAL',
                  boldValue: `${BigNumber((parseFloat(rentCost)) / (EVM_CONSTANT)).toFixed(6)} ETH`,
                //value: '(18 USD)',
                }}
              />

              {!isMobile && (
                <Flex styles={BaseStyles.buttonContainer}>
                  <Button
                    text='Confirm'
                    variant='contained'
                    loading={loading}
                    onClick={() => writeRent()}
                  />
                </Flex>
              )}
            </Flex> 

            {error && (
              <Snackbar
                anchorOrigin={ { horizontal: 'right', vertical: 'top' } }
                open={error} autoHideDuration={6000}
                onClose={() => resetError()}
              >
                <Alert
                  onClose={() => resetError()}
                  severity="error"
                >
                  There has been an error, please try again
                </Alert>
              </Snackbar>
            )}    
          </Flex>
        </SectionHeader>
      </Flex>
    </Flex>
  );
};

export default Summary;
