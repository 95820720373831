import { addSeconds } from 'date-fns';

// Utils
import { converter, ConverterTypes } from './converter';

/**
 * merge both info into the same NFT object
 * @param nftData 
 * @param nftListing 
 * @returns NFT
 */
export const mergeNftQueries = (nftData?: NFTData, nftListing?: NFTListing) => {
  if (nftData && nftListing) {
    return { ...nftData, ...nftListing }; 
  }
    
  if (nftData) {
    return nftData; 
  }
    
  if (nftListing) {
    return nftListing; 
  }

  return null;
};

/**
 * find a NFT looking for the index 
 * @param {NFT[]} list nft list
 * @param {string | undefined} index 
 * @returns {NFT}
 */
export const getNftSelected = (list: NFTListing[], index?: string) => {
  if (!index) {
    return undefined;
  }
  
  return list.find(item => (+item.index === +index));
};

/**
 * Returns a listing NFT with all fields parsed
 * @param {NFTRaw} nft nft raw
 * @returns {NFTListing | undefined}
 */
export const getNftParsed = (nft?: NFTRaw): NFTListing | undefined => {
  if (!nft) {
    return undefined;
  }

  try {
    const rentStartedTimestamp = !!+nft.rentStartedTimestamp ? new Date(converter.to(
      nft.rentStartedTimestamp as unknown as number,
      ConverterTypes.BIG_NUMBER,
    ) as number * 1000) : undefined;

    const rentSeconds = converter.to(nft.rentSeconds, ConverterTypes.BIG_NUMBER) as number;
    const nftParsed = {
      nftId: (+nft.tokenId).toString(),
      status: nft.status, // default
      index: +nft.index,
      owner: nft.owner,
      renter: nft.renter,
      rentStartedTimestamp,
      rentPriceSecond: converter.to(nft.rentPriceSecond, ConverterTypes.BIG_NUMBER) as number,
      maximumSecondsToRent: converter.to(nft.maximumSecondsToRent, ConverterTypes.BIG_NUMBER)  as number,
      tokenId: converter.to(nft.tokenId, ConverterTypes.BIG_NUMBER) as number,
      // Missing
      expirationDate: rentStartedTimestamp ? addSeconds(rentStartedTimestamp, rentSeconds) : undefined,
      rentCost: 0,
      collection: nft.tokenAddr?.toLowerCase(),
    };
    
    return {
      ...nftParsed,
      status: converter.to(nftParsed, ConverterTypes.STATUS),
    };
  } catch (e) {
    return undefined;
  }
};