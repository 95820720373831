import React from 'react';

// Shared
import {  Flex } from 'components/Shared';

// Styles
import BaseStyles from './style';
import RentList from 'components/BussinesShared/RentList';

interface HomeProps {
}

const Home: React.FC<HomeProps> = () => {
  return (
    <Flex styles={BaseStyles.root}>
      <RentList />
    </Flex>
  );
};

export default Home;
