import React from 'react';

//Date Utils
import { addDays, addHours, addMinutes, differenceInSeconds } from 'date-fns';

//Env
import { envKeys } from 'config';

// Hooks
import useCryptoWrite from 'api/hooks/useCryptoWrite';

//Utils
import { converter, ConverterTypes } from 'api/utils';
import BigNumber from 'bignumber.js';

//Types
import { SelectOptions } from 'pages/Lend/Steps/CardTimeConfigurationDelegate/CardTimeConfigurationDelegate';

//Constants
import { EVM_CONSTANT } from 'utils/converter';
import { SECONDS_IN_24_HS } from 'utils/dateUtils';

interface UseLendConfirmProps {
  id: string;
  price: number;
  listPeriod: number;
  collection?: string;
  onClick?: () => void;
}

interface UseDelegationConfirmProps {
  id: string;
  wallet: string;
  time: number;
  timeType: string;
  collection?: string;
  onClick?: () => void;
  // SelectOptions: string;
}

interface UseConfirmResponse {
  error: boolean;
  loading: boolean;
  setError: (value: boolean) => void;
  onConfirm: () => void;
}

type UseConfirmProps = UseDelegationConfirmProps | UseLendConfirmProps;

// Conditional Types
function useConfirm(data: UseLendConfirmProps, isDelegation: false): UseConfirmResponse;
function useConfirm(data: UseDelegationConfirmProps, isDelegation: true): UseConfirmResponse;
function useConfirm(data: UseConfirmProps, isDelegation: boolean): UseConfirmResponse;

function useConfirm(
  data: UseLendConfirmProps | UseDelegationConfirmProps,
  isDelegation = false,
) {
  const {
    id,
    collection,
    price = 0,
    listPeriod,
    wallet,
    timeType,
    time,
    onClick,
  } = data as any;

  // States
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const secondsToRent = differenceInSeconds(addDays(new Date(), listPeriod), new Date());
  const pricePerSec = BigNumber(price / SECONDS_IN_24_HS).times(EVM_CONSTANT).toFixed(0);//TODO: integrate price

  const delegationTime = timeType === SelectOptions.Days ? addDays(new Date(), time) : 
    timeType === SelectOptions.Hours ? addHours(new Date(), time) : addMinutes(new Date(), time);
  
  // Delegation Mutations
  const { write: writeSetUser, data: dataDelegate, isLoading: isLoadingSetUser } = useCryptoWrite('setUser', 
    {
      args: [
        converter.to(id || 0, ConverterTypes.BIG_NUMBER),
        wallet,
        converter.to(delegationTime.getTime(), ConverterTypes.BIG_NUMBER),
      ],
      onError: () => {
        setError(true);
      },
      onSuccess: () => {
        setLoading(true);
      },
    },
    collection,
  );

  // Lend Mutations
  const { write: writeLend, data: lendData, isLoading: isLoadingLend } = useCryptoWrite('lend',
    {
      args: [
        collection || envKeys.contract.address!,
        converter.to(id, ConverterTypes.BIG_NUMBER),
        pricePerSec,
        converter.to(secondsToRent, ConverterTypes.BIG_NUMBER),
      ],
      onSuccess: () => {
        setLoading(true);
      },
      onError: () => {
        setError(true);
      },
    },
  );

  const { write: approve, isLoading: isLoadingApproved } = useCryptoWrite(
    'approve', 
    { args: [envKeys.contract.wakeUp!, id],
      onSuccess: () => writeLend(),
      onError: () => {
        setError(true);
      },
    },
    collection,
  );

  // Effects
  React.useEffect(()=>{
    if (lendData) {
      const getTxMetadata = async () => {
        await lendData?.wait();
        setLoading(false);
        onClick();
      };
      getTxMetadata();
    }
  }, [lendData, onClick]);

  React.useEffect(()=>{
    if (dataDelegate) {
      const getTxMetadata = async () => {
        await dataDelegate?.wait();
        setLoading(false);
        onClick();
      };
      getTxMetadata();
    }
  }, [dataDelegate, onClick]);
  
  // Handlers
  const onConfirm = () => {
    if (isDelegation) {      
      return writeSetUser();
    }
    
    return approve();
  };

  return {
    error,
    loading: isLoadingApproved || isLoadingLend || isLoadingSetUser || loading,
    setError,
    onConfirm,
  };
}

export default useConfirm;
