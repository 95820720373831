import { BrowserRouter } from 'react-router-dom';

// Material
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Apollo
import { ApolloProvider } from '@apollo/client';

// Integration
import { WagmiConfig } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';

// Hooks
import useUser from 'hooks/useUser';

// Components
import Router from 'components/routes/Router/Router';

// Config
import { client } from 'config';
import { routes } from 'components/routes/config';
import { chains, wagmiClient } from 'config/connectWallet';

// Contexts
import 'styles/globalStyles.css';

// Styles
import { NftsProvider } from 'contexts/nfts/Nfts';

const flag = false;

const UserRoutes = () => {
  const { isConnected } = useUser();

  const allRoutes = !isConnected && flag ? routes.public : [...routes.public, ...routes.private];

  return (
    <Router routes={allRoutes} />
  );
};

const App = () => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApolloProvider client={client}>
            <NftsProvider>
              <BrowserRouter>
                <UserRoutes />
              </BrowserRouter>
            </NftsProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
