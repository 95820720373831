const getStyles = (isMobile: boolean) => ({
  root: {
    padding: '64px 0px',
  },
  body: {
    gap: 24,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: isMobile ? '100%' : undefined,
  },
  stepperSection: {
    gap: 24,
    alignItems: 'flex-end',
    width: isMobile ? '100%' : undefined,
  },
  arrowStyles: {
    cursor: 'pointer',
  },
  headerStyles: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default getStyles;
