enum FilterCategories {
  All = 'All',
  Art = 'Art',
  Gaming = 'Gaming',
  Memberships = 'Memberships',
}
  
const filters: any = [ //TODO: Provisory for demo
  /* FilterCategories.All,
  FilterCategories.Gaming, 
  FilterCategories.Memberships,  */
];

export {
  filters,
  FilterCategories,
};
