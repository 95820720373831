import React, { useEffect, useState } from 'react';

// Business
import { NftCard } from 'components/BussinesShared';
import { NftCardProps } from 'components/BussinesShared/NftCard/NftCard';

// Hooks
import { useGetNftDataById } from 'api/schema/nft';

//Crypto
import useCryptoWrite from 'api/hooks/useCryptoWrite';

//Types
import { NftStatus } from 'enums';

//Router
import { useNavigate } from 'react-router-dom';
import { redirect } from 'components/routes/config';

interface AsyncNftCardProps extends Partial<NftCardProps> {
  index: string;
  filteredNft: NFTListing;
  nftId: string;
  rent?: boolean;
  collection?: string;
}

const AsyncNftCard: React.FC<AsyncNftCardProps> = ({ filteredNft, nftId, ...props }) => {
  const { loading: loading, data: nft } = useGetNftDataById(nftId, filteredNft.collection || props.collection);
  const [cancelIndex, setCancelIndex] = useState(-1);
  const { write: cancelLend } = useCryptoWrite('cancelLend', { args: [cancelIndex] });
  const navigate = useNavigate();
  
  useEffect(()=> {
    if (cancelIndex !== -1) {
      cancelLend();
    }
  }, [cancelIndex, cancelLend]);

  const getOnClickButton = (nftClick: NFTListing) =>{
    if (nftClick.status === NftStatus.LISTED) {
      setCancelIndex(nftClick.index);
    } else if (nftClick.status === NftStatus.LENDED || nftClick.status === NftStatus.DELEGATE)
      window.open('https://testnet.tokengated.wakeuplabs.io/', '_blank');
    else if (nftClick.status === NftStatus.AVAILABLE)
      navigate(redirect.lend({ nftId: nftClick?.nftId!, collection: filteredNft.collection! }));
    
    return ()=>{};
  };
  
  return (
    <NftCard
      onClickButton={() => getOnClickButton(filteredNft)}
      title={nft ? `${nft.name} ${nftId}` : ''}
      subtitle={nft?.description || ''}
      image={nft?.image}
      loading={loading || !nft?.name}
      disableHover={false}
      {...props}
    />
  );
};

export default AsyncNftCard;
