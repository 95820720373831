import { BigNumber } from 'ethers';

export enum ConverterTypes {
  BIG_NUMBER = 'bignumber',
}

export const EVM_CONSTANT = 10 ** 18;

const to = (data?: number | string | null, type: ConverterTypes = ConverterTypes.BIG_NUMBER) => {
  if (!data) {
    return data;
  }

  if (type === ConverterTypes.BIG_NUMBER) {
    return +BigNumber.from(+data);
  }
};

export const converter = { to };

