import Router from 'components/routes/Router/Router';
import React from 'react';

// Routes
import { routes } from './routes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RentProps {
}

const Rent: React.FC<RentProps> = () => {
  return (
    <Router routes={routes} />
  );
};

export default Rent;
