import React from 'react';

// Shared
import { Filter, Flex } from 'components/Shared';

// Utils
import { generateKey } from 'utils/react';

// Hooks
import useStyles from 'hooks/useStyles';

// Styles
import getStyles from './style';

interface FilterOption {
  label: string;
  value: string;
}

interface FiltersProps {
  filters: FilterOption[];
  selected?: FilterOption;
  onChangeFilter: (filter: FilterOption) => void;
}

const Filters: React.FC<FiltersProps> = ({
  filters,
  selected,
  onChangeFilter,
}) => {

  const { BaseStyles } = useStyles({ getStyles });

  return (
    <Flex styles={BaseStyles.root} row>
      <div style={BaseStyles.container}>
        {filters.map(filter => (
          <Filter
            key={generateKey('nft-filters-')}
            text={filter.label}
            selected={selected && filter.value === selected.value}
            onClick={()=> onChangeFilter(filter)}
          />
        ))}
      </div>
    </Flex>
  );
};

export default Filters;
