//Utils
import BigNumber from 'bignumber.js';

//Constants
import { EVM_CONSTANT } from './converter';
import { SECONDS_IN_24_HS } from './dateUtils';

export const getPricePerDayFromPerSec = (price: number) => (
  BigNumber((price) / (EVM_CONSTANT))
    .times(SECONDS_IN_24_HS)
).toFixed(6);