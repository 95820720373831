import { redirect } from 'components/routes/config';
import { useNavigate, useParams } from 'react-router-dom';

export const useTab = () => {
  // Hooks
  const { activeTab } = useParams();
  const navigate = useNavigate();

  // Handlers
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(redirect.myNfts({ activeTab: newValue }));
  };

  return {
    activeTab,
    handleChange,
  };
};