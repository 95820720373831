
export enum NftStatus {
  DELEGATE = 'delegate', // puede estar listado
  
  // contract wakeup.sol
  RENTED = 'rented', // expiration > hoy & userof
  LISTED = 'listed', // listado sin userof 
  LENDED = 'lended', //userof y listado

  // default
  AVAILABLE = 'available',
}

// 0 listado
// 1 rentado expiration > now

export enum FilterText {
  All = 'All',
  Available = 'Available',
  Delegated = 'Delegated',
  Listed = 'Listed',
  Lended = 'Lended',
  Unavailable = 'Unavailable',
  Rented = 'Rented',
}