import React from 'react';
import BaseStyles from './styles';

export interface CustomDividerProps {
  styles?: Styles;
}

const DividerCustom: React.FC<CustomDividerProps> = ({
  styles = {},
}) => (
  <div style={{ ...BaseStyles.dividerStyles, ...styles }} />
);

export default DividerCustom;
