import { Colors } from 'styles';

const BaseStyles: Styles = {
  root: {
    gap: 24,
  },

  priceInfo: { 
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: `${Colors.lightGray}`,
    height: '59px',
    width: '165px',
    borderRadius: '4px',
    border: `1px solid ${Colors.darkGray}`,
  },
  textFieldPrice: { 
    sx: { width: '165px' }, 
  },
  priceContainer: { 
    flexDirection: 'row',
    gap: 8,
  },

  //Containers
  mainBox: {
    alignItems: 'center',
    gap: 24,
  },
  container: {
    gap: 24,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    alignItems: 'flex-end',
    width: '100%',
  },
  footerCardContainer: {
    flexDirection: 'row',
    marginTop: '8px',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'flex-start', 
  },
};
  
export default BaseStyles;
  