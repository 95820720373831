import React from 'react';

//Material UI
import { Box } from '@mui/material';

//Shared Components
import CustomTabs from 'components/Shared/CustomTabs/CustomTabs';
import { CustomText } from 'components/Shared';

//Bussiness Shared
import { CardWithImage as EmptyStateCard } from 'components/BussinesShared';

// Config
import AllNftsTab from 'components/MyNfts/AllNftsTab/AllNftsTab';

// Hooks
import { useAccount } from 'wagmi';
import { useTab } from 'hooks/useTab';
import { useNftList } from 'contexts/nfts';

// Styles
import { FontWeight, FontSize } from 'styles';

// Types
import { NftStatus } from 'enums';
import { FilterText } from 'enums/nft';
import { MyNFTsTabs } from '../types';

//Images
import { Empty_state_wallet } from 'Images';

const tabs = [
  /* { label: 'All', value: MyNFTsTabs.ALL }, */
  { label: 'Own', value: MyNFTsTabs.OWN },
  { label: 'Received', value: MyNFTsTabs.RECEIVED },
];

// eslint-disable-next-line max-len
const line1 = 'When you connect your wallet you will see not only your own NFTs, but also the ones that were delegated to you.';

const filters = [
  { label: FilterText.All, value: null },
  { label: FilterText.Available, value: NftStatus.AVAILABLE },
  { label: FilterText.Delegated, value: NftStatus.DELEGATE },
  { label: FilterText.Lended, value: NftStatus.LENDED },
  { label: FilterText.Listed, value: NftStatus.LISTED },
  { label: FilterText.Rented, value: NftStatus.RENTED },
];

const defaultFilter = filters[0];

const MyNfts: React.FC = () => {
  // Hooks
  const { ownNfts, receivedNfts, isLoading, isListed } = useNftList();
  const { data: rainbowData } = useAccount();
  const { activeTab = MyNFTsTabs.DEFAULT, handleChange } = useTab();

  const getIsDisabledButton = (nft: NFTListing) => {
    return isListed(nft.nftId);
  };

  const getLabelButton = (nft: NFTListing) =>{
    if (nft.status === NftStatus.LISTED)
      return 'Cancel Listing';
    
    return 'Delegate';
  };

  if (!rainbowData?.address) {
    return (
      <Box paddingTop='32px'>
        <EmptyStateCard
          Icon={Empty_state_wallet}
          title="You need to connect your wallet"
          description={[line1]}
          callToActionProps={{ connect: true }}
        />
      </Box>
    );
  }
  
  return (
    <div style={{ width: '100%' }}>
      {/* Title */}
      <CustomText
        style={{ paddingTop: 24 }}
        fontWeight={FontWeight.extraBold}
        fontSize={FontSize.xxxxlarge}
      > 
        My NFTs
      </CustomText>

      <CustomTabs selected={activeTab} onChange={handleChange} tabs={tabs}>
        {activeTab === MyNFTsTabs.OWN && (
          <AllNftsTab
            isLoading={isLoading}
            nfts={ownNfts} //Should show all nfts of user
            isDisabledButton={(nft: NFTListing) => getIsDisabledButton(nft)}
            labelButton={(nft:NFTListing) => getLabelButton(nft)}
            filterOptions={filters}
            defaultFilter={defaultFilter}
          />
        )}
        {activeTab === MyNFTsTabs.RECEIVED && (
          <AllNftsTab
            isLoading={isLoading}
            nfts={receivedNfts}//Should show all Nfts Received & Delegated to the current user
            isDisabledButton={()=>false}
            labelButton={()=>'Use NFT'}
            receivedTab
          />
        )}
      </CustomTabs>
    </div>
  );
};

export default MyNfts;
