//Material
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material';

//Styles
import { Colors } from 'styles';

interface CustomStepperProps {
  styles?: Styles;
  steps: Array<string>;
  activeStep: number
} 

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Colors.greenLight,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Colors.greenLight,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const CustomStepper: React.FC<CustomStepperProps> = ({ styles = {}, steps, activeStep }) =>{
  return (
    <Box sx={{ width: '100%', ...styles }}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector/>}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel /* StepIconComponent={IconStepper} */>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;