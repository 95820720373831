import React from 'react';

// Hooks
import { useParams } from 'react-router-dom';
import useStyles from 'hooks/useStyles';
import useDevice from 'hooks/useDevice';

// Business
import { NftCard, NotFound, SectionHeader } from 'components/BussinesShared';

// Shared
import { Flex, Loading } from 'components/Shared';

// Internal
import {
  Available,
  Delegate,
  Lended,
  Listed,
} from './Status';

// Api
import { useGetNftById, useGetNftDataById } from 'api/schema/nft/queries';

// Styles
import getStyles from './style';

// Context
import { useNftList } from 'contexts/nfts';

interface NftDetailsProps {
}

enum NftStatus {
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  LENDED = 'lended',
  RENTED = 'rented',
  LISTED = 'listed',
  DELEGATE = 'delegate',
}

const NftDetails: React.FC<NftDetailsProps> = () => {
  // Hooks
  const { isMobile } = useDevice();
  const { index, nftId, collection } = useParams();
  const { data: nftListed, loading } = useGetNftById(index);
  const { ownNfts } = useNftList();
  const nftOwn = ownNfts.find(n => n.nftId == nftId);
  const { BaseStyles } = useStyles({ getStyles });
  const { data: nftData, loading: isLoadingData } = useGetNftDataById(nftId!, collection);

  let nft: Partial<NFT> = {};
  if (!nftListed && !loading && !isLoadingData && nftOwn) {
    nft = { ...nftOwn, expirationDate: new Date(+(nftOwn as any).expires), ...nftData };
  } else {
    nft = { nftId: nftId, ...nftListed, ...nftData };
  }

  const getStatus = (nftParam: NFT) => {
    switch (nftParam.status) {
      case NftStatus.AVAILABLE:
        return <Available nft={nft as NFT} />;
      case NftStatus.LENDED:
      case NftStatus.RENTED:
        return <Lended nft={nftParam} />;
      case NftStatus.LISTED:
        return <Listed nft={nftParam} />;
      case NftStatus.DELEGATE:
        return <Delegate nft={nftParam} />;
      default:
        break;
    }
  };

  if (isLoadingData || loading || !nft.name) {
    return <Loading />;
  }
  
  if (!nft && (!isLoadingData || !loading)) {
    return <NotFound />;
  }

  return (
    <Flex>
      <Flex styles={BaseStyles.root}>
        <SectionHeader
          title={nft.name}
        >
          <Flex styles={BaseStyles.body} row={!isMobile}>
            <div style={BaseStyles.nftContainer}>
              <NftCard
                {...nft as NFT}
                title={`${nft.name} ${nftId}`}
                subtitle={nft.description || ''}
                disableHover
                loading={!nft}
              />
            </div>

            {getStatus(nft as NFT)}
          </Flex>
        </SectionHeader>
      </Flex>
    </Flex>
  );
};

export default NftDetails;
