// Internal components
import Root from './Root/Root';

const paths = {
  empty: '/',
  activeTab: '/:activeTab',
};

export const routes = [
  {
    to: paths.empty,
    path: paths.empty,
    Page: Root,
    exact: true,
  },
  {
    path: paths.activeTab,
    Page: Root,
    exact: true,
  },
];
