import * as yup from 'yup';

export const schema = yup.object({
  time: yup.number().positive().moreThan(0),
  timeType: yup.string(),
}).required();

export type FieldsFormType = yup.InferType<typeof schema>;

export const defaultValues = {
  time: 0,
  timeType: 'Days',
};