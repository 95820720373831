//Config
import { envKeys } from 'config';

//Crypto
import contractInterface from './wakeUp-abi.json';
import defaultContractInterface from './contract-abi.json';

export const defaultContractConfig = {
  addressOrName: envKeys.contract.address || '',
  contractInterface: defaultContractInterface,
};

export const wakeupContractConfig = {
  addressOrName: envKeys.contract.wakeUp || '',
  contractInterface: contractInterface,
};
