// Internal components
import ExampleForm from './ExampleForm/ExampleForm';
import Root from './Root';
import TestRent from './TestRent';

const paths = {
  empty: '/',
  testForm: '/test-form',
  testRent: '/test-rent',
};

export const routes = [
  {
    to: paths.empty,
    path: paths.empty,
    Page: Root,
    exact: true,
  },
  {
    to: paths.testForm,
    path: paths.testForm,
    Page: ExampleForm,
    exact: true,
  },
  {
    to: paths.testRent,
    path: paths.testRent,
    Page: TestRent,
    exact: true,
  },
];
