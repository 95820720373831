const BaseStyles = {
  customCardStyles: { 
    width: 410, 
    height: '100%', 
    padding: '32px', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
    gap: 24, 
  },
  textFieldPrice: { 
    sx: { width: '165px' }, 
  },
  priceContainer: { 
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 8,
  },
  delegationStyles: {
    alignItems: 'flex-start',
    gap: 16, 
  },
  footerCardContainer: {
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'flex-start', 
  },
};

export default BaseStyles;
