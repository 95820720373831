import { Colors } from 'styles';
import { minHeightTobBar } from 'styles/constants';

const getStyles = (isMobile: boolean) =>  ({
  //Containers
  root: {
    width: '100%',
    minHeight: `calc(100vh - ${minHeightTobBar})`,
    padding: 12,
  },
  body: {
    width: isMobile ? '100%' : '90%',
    margin: 'auto',
  },
  backgroundDifuminated: { 
    background: `linear-gradient(180deg, ${Colors.violetSecondary}  0%,rgba(255, 255, 255, 0) 66%)`,
  },
  backgroundGray: { 
    background: Colors.extraLightgray,
  },
});

export default getStyles;
