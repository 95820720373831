import React, { useEffect, useState } from 'react';

// Shared
import {
  CustomCard,
  CustomText,
  Flex,
  DividerCustom,
  TextField,
  Button,
} from 'components/Shared';

//Material
import Switch from '@mui/material/Switch';

//Form
import { useWatch } from 'react-hook-form';
import { isObjectEmpty } from 'utils/objectUtils';

// Hooks
import useDevice from 'hooks/useDevice';

// Config
import { envKeys } from 'config';

//Types
import { Control, FieldErrorsImpl, UseFormSetValue } from 'react-hook-form/dist/types';
import { FieldsFormType } from 'pages/Lend/Validation';

// Styles
import BaseStyles from './style';
import { FontSize, FontWeight } from 'styles';

interface CardPriceConfigurationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  errors: FieldErrorsImpl;
  setValue: UseFormSetValue<FieldsFormType>;
  goToNextStep: () => void;
}

const CardPriceConfiguration: React.FC<CardPriceConfigurationProps> = ({ control, errors, setValue, goToNextStep }) => {
  // States
  const [ethPrice, setEthPrice] = useState('');

  // Hooks
  const { isMobile } = useDevice();
  const [isDelegation, price = '', wallet = ''] = useWatch({ control, name: ['delegation', 'price', 'wallet'] });
  
  const disabledButton = (isDelegation
    ? (wallet.length < 1 || !isObjectEmpty(errors?.wallet)) 
    : !isObjectEmpty(errors?.price) );

  // Handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('delegation', event.target.checked);
  };

  // Effects
  useEffect(()=>{
    const getPrice = async ()=>{
      const res = await (await fetch(envKeys.eth.price || '')).json();
      setEthPrice(res.price);
      setValue('ethPrice', parseFloat(res.price));
    };
    getPrice();
  }, [setValue]);
  
  return (
    <>
      <CustomCard size='medium' style={BaseStyles.customCardStyles}>
        <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.medium}>
          Define the day cost of the NFT
        </CustomText>

        <Flex styles={BaseStyles.priceContainer}> 
          <TextField
            name='price'
            control={control}
            textFieldProps={{
              sx: { ...BaseStyles.textFieldPrice },
              label: 'Daily price (ETH)',
              type: 'number',
              disabled: isDelegation,
            }}
          />
          =
          <Flex styles={BaseStyles.priceInfo}>
            <CustomText fontWeight={FontWeight.medium}>
              {(price * parseFloat(ethPrice)).toFixed(2)}
            </CustomText>
            <CustomText fontWeight={FontWeight.medium}>
              USD
            </CustomText>
          </Flex>
        </Flex>

        <DividerCustom />

        <Flex styles={{ flexDirection: 'row' }}>
          <Switch checked={isDelegation} onChange={handleChange} />
          <CustomText fontWeight={FontWeight.regular} fontSize={FontSize.small}>
            I want to delegate <b>for free</b> to an especific adress
          </CustomText>
        </Flex>

        {isDelegation && (
          <Flex styles={BaseStyles.delegationStyles}>
            <CustomText fontSize={FontSize.xlarge}  fontWeight={FontWeight.medium}>
              Who do you want to free delegate it to?
            </CustomText>
            <TextField
              name='wallet'
              control={control}
              textFieldProps={{
                label: 'Metamask address',
                fullWidth: true,
                errorMessage: !!errors?.wallet && isDelegation 
                  ? errors?.wallet.message
                  : undefined,
              }}
            />
          </Flex>
        )}
      </CustomCard>

      {!isMobile && (
        <Button
          text='Next'
          disabled={disabledButton} //TODO: Check how to use validation with defaultValues
          onClick={goToNextStep}
        />
      )}
    </>
  );
};

export default CardPriceConfiguration;
