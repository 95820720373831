import * as yup from 'yup';

export const schema = yup.object({
  TextName: yup.string().required(),
  Age: yup.number().positive().integer().required(),
  Email: yup.string().email().required(),
  Gender: yup.string().required(),
}).required();

export type FieldsFormType = yup.InferType<typeof schema>;

export const defaultValues = {
  TextName: 'TestDefault',
  Age: 0,
  Email: '',
  Gender: '',
};