import { gql } from '@apollo/client';

// Queries
export const rentInformationsOwner = gql`
  query($owner: String!) {
    rentInformations(where: {owner_contains:$owner}) {
      id
      user
      expires
      owner
      collection
      nftId
    }
  }
`;

export const rentInformationsUser = gql`query($user: String!) {
  rentInformations(where: {user_contains:$user}) {
    id
    user
    expires
    owner
    collection
    nftId
    }
  }
`;

export const rentList = gql`query{
  rentListEntities {
      id
      tokenAddr
      tokenId
      owner
      rentPriceSecond
      maximumSecondsToRent
      index
    }
  }
`;

export const rentedList = gql`query{
  rentNFTEntities{
      id
      renter
      expires
      rentCost
      index
      tokenId
      tokenAddr
      rentStartedTimestamp
    }
  }
`;

