import React from 'react';

// Hooks
import useRest from 'api/hooks/useRest';
import useCryptoRead from 'api/hooks/useCryptoRead';

// Utils
import {
  mergeNftQueries,
  converter,
  ConverterTypes,
  getNftSelected,
} from 'api/utils';

// Context
import { useNftList } from 'contexts/nfts';
import { parseIpfs } from 'api/utils/tokenUri';
import useCryptoWrite from 'api/hooks/useCryptoWrite';

interface GetNftDataResponse {
  data: NFTData | undefined;
  loading: boolean;
  error?: RestError | Error | null;
} 

/**
 * Returns an NFTData searching by index
 * @param index nft index
 * @returns {GetNftDataResponse}
 */
const useGetNftDataByIndex = (index?: string): GetNftDataResponse => {
  const { allNfts } = useNftList();
  const nftSelected = getNftSelected(allNfts, index);

  // States
  const [url, setUrl] = React.useState<string | null>(null);

  // Hooks
  const {
    error: errorBase,
    isLoading: loadingBase,
  } = useCryptoRead<string>(
    'tokenUri',
    {
      enabled: !!nftSelected,
      args: nftSelected ? [converter.to(+nftSelected.tokenId % 7, ConverterTypes.BIG_NUMBER)] : undefined,
      onSuccess: (data: unknown) => {
        if (!nftSelected) {
          return undefined;
        }
        
        setUrl(data as string);
      },
    },
  );

  const {
    data,
    error: errorData,
    loading: loadingData,
  } = useRest<NFTData | undefined>(url!, { enabled: !!url });

  const response: GetNftDataResponse = {
    loading: loadingBase || loadingData,
    data,
    error: errorData || errorBase,
  };

  return response;
};

interface GetNftDataResponse {
  data: NFTData | undefined;
  loading: boolean;
  error?: RestError | Error | null;
} 

/**
 * Returns an NFTData searching by id
 * @param id nft id
 * @returns {GetNftDataResponse}
 */
const useGetNftDataById = (id: string, collectionAddress?: string): GetNftDataResponse => {
  // States
  const [url, setUrl] = React.useState<string | null>(null);
  const idTogetWithModule = collectionAddress === '0xfEFF513C2C358bB01F650ce889c05999563Cfb4b'
    ? converter.to(parseFloat(id) % 7 !== 0
      ? parseFloat(id) % 7 : 5, ConverterTypes.BIG_NUMBER)
    : id;//TODO: Remove when real collection

  // Hooks
  const {
    error: errorBase,
    isLoading: loadingBase,
  } = useCryptoRead<string>(
    'tokenUri',
    {
      args: [idTogetWithModule],
      onSuccess: (data: unknown) => {
        if (!data) {
          return undefined;
        }
        const urlParsed = parseIpfs(data as string, id);
        setUrl(urlParsed as string);
      },
    },
    collectionAddress,
  );

  const {
    data,
    error: errorData,
    loading: loadingData,
  } = useRest<NFTData | undefined>(url!, { enabled: !!url });

  const response: GetNftDataResponse = {
    loading: loadingBase || loadingData,
    data,
    error: errorData || errorBase,
  };

  return response;
};

interface GetNftListingResponse {
  data: NFTListing | undefined;
  loading: boolean;
  error?: RestError | Error | null;
} 

/**
 * Returns an NFTListing searching by index
 * @param index nft index
 * @returns {GetNftListingResponse}
 */
const useGetNftListingById = (index?: string): GetNftListingResponse => {
  // Hooks
  const { allNfts, isLoading, error } = useNftList();
  
  // Utils
  const nft = getNftSelected(allNfts, index);

  return {
    loading: isLoading,
    error,
    data: nft,
  };
};

interface GetNftResponse {
  data: NFT | undefined;
  loading: boolean;
  error?: RestError | Error | null;
} 

/**
 * Returns an NFT searching by index. It merge both Nft listing and nft data queries
 * @param index nft index
 * @returns {GetNftResponse}
 */
const useGetNftById = (index?: string): GetNftResponse => {
  const { data: nftData, loading: isLoadingData, error: errorData } = useGetNftDataByIndex(index);
  const { data: nftListing, loading: isLoadingListing, error: errorListing } = useGetNftListingById(index);

  return {
    loading: isLoadingData || isLoadingListing,
    data: mergeNftQueries(nftData, nftListing) as NFT,
    error: errorData || errorListing,
  };
};

interface UseRentProps {
  index: string;
  secondsToRent: number;
  rentCost: string;
  onComplete: () => void;
}

const useRent = ({
  index,
  secondsToRent,
  rentCost,
  onComplete,
}: UseRentProps) => {
  // States
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  // Hooks
  const { data: rentData, write } = useCryptoWrite('rent', 
    {
      args: [converter.to(index, ConverterTypes.BIG_NUMBER), converter.to(secondsToRent, ConverterTypes.BIG_NUMBER)],
      overrides: {
        value: rentCost.toString(),
      },
      onError: () => {
        setLoading(false);
        setError(true);
      },
    },
  );

  React.useEffect(() => {
    if (rentData) {
      
      const getTxMetadata = async () => {
        await rentData?.wait();
        setLoading(false);
        onComplete();
      };

      getTxMetadata();
    }
  }, [onComplete, rentData]);

  const resetError = () => setError(false);

  const writeRent = () => {
    setLoading(true);
    write();
  };

  return {
    writeRent,
    loading,
    rentData,
    error,
    resetError,
  };
};

export { useGetNftDataByIndex, useGetNftListingById, useGetNftById, useGetNftDataById, useRent };
