import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material
import { Box } from '@mui/material';

//Styles
import { boxMobileStyles, boxStyles } from './styles';
import { Colors } from 'styles/colors';

//Components
import EmptyState from './EmptyState';
import AsyncNftCard from 'components/BussinesShared/AsyncNftCard/AsyncNftCard';
import Filters from 'components/BussinesShared/Filters';

//Hooks
import useDevice from 'hooks/useDevice';

// Config
import { redirect } from 'components/routes/config';

// Types
import { NftStatus } from 'enums';
import { FilterText } from 'enums/nft';

//Utils
import { generateKey } from 'utils/react';

const cardData = {
  [NftStatus.LISTED]: {
    label: FilterText.Listed,
    color: Colors.lightYellow,
  },
  [NftStatus.RENTED]: {
    label: FilterText.Rented,
    color: Colors.lightBlue,
  },
  [NftStatus.LENDED]: {
    label: FilterText.Lended,
    color: Colors.lightBlue,
  },
  [NftStatus.DELEGATE]: {
    label: FilterText.Delegated,
    color: Colors.redLigt,
  },
  [NftStatus.AVAILABLE]: {
    label: FilterText.Available,
    color: Colors.green,
  },
};

interface NftsTabProps {
  nfts: NFTListing[];
  defaultFilter?: FilterProps;
  filterOptions?: FilterProps[];
  labelButton: (nft: NFTListing) => string;
  isLoading: boolean;
  isDisabledButton: (nft: NFTListing) => boolean;
  receivedTab?: boolean;
}

const AllNftsTab = ({
  nfts,
  defaultFilter,
  filterOptions,
  labelButton,
  isLoading,
  isDisabledButton,
  receivedTab,
}: NftsTabProps) => {
  //Hooks
  const { isMobile }  = useDevice();

  // States
  const [selectedFilter, setSelectedFilter] = useState<FilterProps | undefined>(defaultFilter);
  const navigate = useNavigate();

  // Handlers
  const getLabel = (nft: NFTListing) => {
    if (nft.status === NftStatus.LENDED && receivedTab) {
      return cardData[NftStatus.RENTED]?.label;
    }
    
    return cardData[nft?.status]?.label;
  };

  const filteredNfts = React.useMemo(() => {
    if (isLoading) {
      return [];
    }

    if (!selectedFilter || !selectedFilter.value) {
      return nfts;
    }

    return nfts.filter(item => (
      item.status === selectedFilter.value
    ));
    
    return [];
  }, [isLoading, nfts, selectedFilter]);
  
  return (
    <>
      <Filters
        filters={filterOptions || []}
        selected={selectedFilter}
        onChangeFilter={setSelectedFilter}
      />

      {!isLoading && filteredNfts?.length < 1
        ? <EmptyState />
        : <Box sx={isMobile ? boxMobileStyles : boxStyles}>
          {filteredNfts.map(nft => {
            return (
              <AsyncNftCard
                key={generateKey('nft-card-' + nft?.nftId)}
                index={nft?.index?.toString()}
                nftId={nft?.nftId}
                label={getLabel(nft)}
                labelTextColor={cardData[nft?.status]?.color}
                onClickIcon={() =>navigate(redirect.nftDetails({
                  index: nft.index,
                  nftId: nft.nftId,
                  collection: nft.collection!,
                }))}
                disabledButton={isDisabledButton(nft)}
                labelButton={labelButton(nft)}
                filteredNft={nft}
              />
            );
          })} 
        </Box>
      }
    </>);
};

export default AllNftsTab;
