import React from 'react';

// Shared
import { CustomCard, CustomText, TextField, Button } from 'components/Shared';

// Styles
import BaseStyles from './style';
import { FontSize, FontWeight } from 'styles';

//Form
import { useWatch } from 'react-hook-form';

//Hooks
import useDevice from 'hooks/useDevice';

//Types
import { Control, FieldErrorsImpl } from 'react-hook-form/dist/types';

interface CardTimeConfigurationLendProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control:  Control<any, any>;
  errors: FieldErrorsImpl;
  id: string;
  goToNextStep: ()=>void;
}

const CardTimeConfigurationLend: React.FC<CardTimeConfigurationLendProps> = ({
  control,
  goToNextStep,
  errors,
}) => {

  // Hooks
  const { isMobile } = useDevice();
  const [listPeriod] = useWatch({ control, name: ['listPeriod', 'minPeriod', 'maxPeriod'] });
  
  const isValidForm = listPeriod > 0;
  /* TODO: commented till more definition   
  && minPeriod > 0 && maxPeriod > 0 &&
    (parseFloat(minPeriod) < parseFloat(maxPeriod));
    */

  return (
    <>
      <CustomCard size='medium' style={BaseStyles.customCardStyles}>
        <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.medium}>
          What's the maximum days that you want your NFT to be rented?
        </CustomText>
        <TextField
          name='listPeriod'
          control={control}
          textFieldProps={{ 
            sx: { ...BaseStyles.textFieldPrice },
            label: 'Renting period in days',
            type: 'number',
            errorMessage: errors?.listPeriod ? errors.listPeriod.message : undefined,
          }}
        />
        {/* <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.medium}>
        Rental min and max time
        </CustomText>
        <Flex styles={BaseStyles.priceContainer}> 
          <TextField
            name='minPeriod'
            control={control}
            textFieldProps={{ 
              sx: { ...BaseStyles.textFieldPrice },
              label: 'Min period', type: 'number' }}
          />
          <TextField
            name='maxPeriod'
            control={control}
            textFieldProps={{ 
              sx: { ...BaseStyles.textFieldPrice },
              label: 'Max period', type: 'number' }}
          />
        </Flex> */}
      </CustomCard>

      {!isMobile && (
        <Button
          text='Next'
          disabled={!isValidForm}//Check how to use validation with defaultValues
          onClick={goToNextStep}
        />
      )}
    </> 
  );
};

export default CardTimeConfigurationLend;
