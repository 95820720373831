import React from 'react';

// Hooks
import useDevice from 'hooks/useDevice';

// Styles
import { StyledPaper } from './style';

interface CustomCardProps {
  children?: React.ReactNode;
  style?: Styles;
  size: 'medium' | 'big';
  Icon?: Icon;
}

const MAX_BIG_WIDTH = 856;
const MAX_MEDIUM_WIDTH = 410;

const CustomCard: React.FC<CustomCardProps> = ({
  style = {},
  size,
  children,
}) => {
  // Hooks
  const { isMobile } = useDevice();

  // Variables
  const maxWidth = size === 'big' ? MAX_BIG_WIDTH : MAX_MEDIUM_WIDTH;
  
  return <StyledPaper style={{ maxWidth: isMobile ? '100%' : maxWidth, ...style }}>
    {children}
  </StyledPaper>;
};

export default CustomCard;
