import { ReactNode, useState } from 'react';

// Material
import { Box, styled } from '@mui/system';
import { IconButton } from '@mui/material';

//Images
import { EyeIcon } from 'Images';

//Shared
import { Flex, CustomText, Button, Loading } from 'components/Shared';

//Hooks
import useDevice from 'hooks/useDevice';
import useStyles from 'hooks/useStyles';

//Utils
import { generateKey } from 'utils/react';

//Styles
import { FontSize, FontWeight } from 'styles/font';
import getStyles, {
  boxButtonStyles,
  boxMobileButtonStyles,
  buttonsFlexStyles,
  StyledBox,
} from './styles';
import { Colors } from 'styles';

export interface CollectionCardProps {
  loading?: boolean;
  title: string;
  subtitle: string;
  images?: string[];
  label?: string;
  labelTextColor?: Colors;
  backgroundLabel?: Colors;
  variant?: 'horizontal' | 'vertical';
  onClickIcon?: () => void;
  IconLabel?: Icon;
  // button
  labelButton?: string;
  onClickButton?: () => void;
  disabledButton?: boolean;
  children?:ReactNode;
  disableHover:boolean;
  onClickCard?: () => void;
}

type FlexProps = {
  vertical: number;
};

const HoverFlex = styled(Box)<FlexProps>(props => {
  const { vertical } = props;

  return (vertical ? {
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  } : {
    cursor: 'pointer',
    maxWidth: '100%',
  });
});

const CollectionCard : React.FC<CollectionCardProps> = ({
  loading,
  title,
  subtitle,
  label,
  images,
  labelTextColor,
  backgroundLabel = Colors.blackLight,
  variant,
  onClickIcon,
  IconLabel,
  // button
  labelButton,
  disabledButton,
  onClickButton,
  children,
  disableHover = false,
  onClickCard,
}) =>{
  // Hooks
  const { isMobile }  = useDevice();
  const [loadedImg, setLoadedImg] = useState(false);

  // Variables
  const isHorizontal = variant ? variant === 'horizontal' : isMobile;
  const { BaseStyles } = useStyles({ getStyles: () => getStyles(isHorizontal) });

  if (loading) {
    return (
      <Flex styles={BaseStyles.loading}>
        <Loading />
      </Flex>
    );
  }

  return (
    <HoverFlex onClick={onClickCard} vertical={!disableHover ? 1 : 0}>
      <Flex styles={BaseStyles.root}>
        <Box>
          {label && (
            <StyledBox style={{ color: labelTextColor, background: backgroundLabel }}>
              {IconLabel && <IconLabel />}
              <CustomText fontSize={FontSize.xxsmall} fontWeight={FontWeight.medium}>
                {label}
              </CustomText>
            </StyledBox>
          )}

          <div style={BaseStyles.imageContainer}>
            {!loadedImg && <Loading />}
            {images && images.map(image => (
              <img
                key={generateKey('collection-image-')}
                src={image}
                alt='collectionImage'
                style={BaseStyles.image}
                onLoad={() => setLoadedImg(true)}
              />
            ))}
          </div>
        </Box>

        <Box sx={isHorizontal ? boxMobileButtonStyles : boxButtonStyles }>
          {/* Title */}
          <CustomText style={BaseStyles.text} fontSize={FontSize.large} fontWeight={FontWeight.extraBold}>
            {title}
          </CustomText>

          {/* Subtitle */}
          <CustomText style={BaseStyles.text}>
            {subtitle}
          </CustomText>

          {/* Buttons */}
          <Flex styles={buttonsFlexStyles}>
            {onClickIcon && (
              <IconButton
                color="primary" 
                sx={{ width: 48, border: '1px solid #8158FC', borderRadius: '12px' }}
                onClick={onClickIcon}
              >
                <EyeIcon />
              </IconButton> 
            )}

            {onClickButton && (
              <Button
                style={{ width: '100%' }}
                color="primary"
                variant="contained"
                disabled={disabledButton}
                disableElevation
                onClick={onClickButton}
                text={labelButton}
                size={isHorizontal ? 'none' : 'large'}
              />
            )}

            {children}
          </Flex>
        </Box>
      </Flex>
    </HoverFlex>
  );
};

export default CollectionCard;
