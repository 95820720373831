import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

// Material
import { CloseOutlined } from '@mui/icons-material';
import { Drawer, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Hooks
import useDevice from 'hooks/useDevice';
import useStyles from 'hooks/useStyles';

// Components
import {
  Button,
  DividerCustom,
  CustomAvatar,
  IconButton,
  CustomText,
  Flex,
} from 'components/Shared';
import TabMenuItem from 'components/TabMenuItem/TabMenuItem';

// Images
import WakeUpLogo from 'Images/WakeUpLogo.svg';

// Utils
import { redirect, tabRoutes } from 'components/routes/config';
import { getAddressHidden } from 'utils/nft';
import { generateKey } from 'utils/react';

// Styles
import { FontSize, FontWeight } from 'styles';
import './Navigation.css';
import getStyles, {
  StyledFlexSpaceBetween,
  StyledRoot,
  TabMenuItemsContainer,
} from './style';

export default function Navigation() {
  // States
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Hooks
  const { data: rainbowData } = useAccount();
  const navigate = useNavigate();
  const { isMobile }  = useDevice();

  const { BaseStyles } = useStyles({ getStyles });

  const isConnected = !!rainbowData?.address;

  return (
    <StyledRoot>
      <StyledFlexSpaceBetween row>
        {isMobile && (
          <>
            <MenuIcon onClick={()=>setDrawerOpen(!drawerOpen)} />
            <Drawer
              anchor='left'
              sx={{ width: '30%' }}
              open={drawerOpen}
              onClose={()=>setDrawerOpen(false)}
            >

              {isConnected && (
                <>
                  <div style={BaseStyles.header}>
                    <Flex styles={BaseStyles.closeContainer}>
                      <IconButton
                        style={BaseStyles.closeIcon}
                        onClick={() => setDrawerOpen(false)}
                      >
                        <CloseOutlined style={BaseStyles.icon} />
                      </IconButton>
                    </Flex>

                    <div style={BaseStyles.addressContainer}>
                      <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.extraBold}>
                        Metamask address:
                      </CustomText>
                      <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.regular}>
                        {getAddressHidden(rainbowData?.address)}
                      </CustomText>
                    </div>

                    <CustomAvatar size="big" />
                  </div>
                  
                  <DividerCustom styles={BaseStyles.divider} />
                </>
              )}

              {tabRoutes.map(route => (
                <ListItem
                  key={generateKey('menu-item')}
                  sx={{ paddingRight: 6 }}
                  style={BaseStyles.listItem}
                >
                  <TabMenuItem
                    navigateTo={route.to!}
                    text={route.label || ''}
                    onClose={() => setDrawerOpen(false)}
                  />
                </ListItem>
              ))}
            </Drawer>
          </>
        )}

        <img src={WakeUpLogo} alt='wakeupLogo' onClick={()=> navigate(redirect.home())} style={{ cursor: 'pointer' }} />
      </StyledFlexSpaceBetween>

      {!isMobile &&       
        <div style={TabMenuItemsContainer}>
          {tabRoutes.map(route => (
            <TabMenuItem
              key={generateKey('menu-item-mobile-')}
              navigateTo={route.to!}
              text={route.label || ''}
            />
          ))}
        </div>
      }

      <Button
        connect={!isConnected}
        disconnect={isConnected}
        color='secondary'
        size="small"
      />
    </StyledRoot>
  );
}
