// Images
import { CalendarIcon, EthSummaryIcon } from 'Images';

//Types
import { SelectOptions } from 'pages/Lend/Steps/CardTimeConfigurationDelegate/CardTimeConfigurationDelegate';

export const delegateSections = ({ address, time, timeType }: 
{ address: string, time: number, timeType: SelectOptions })=> [{
  title: 'Address',
  Icon: EthSummaryIcon,
  lines: [
    {
      label: address,
      withDivider: true,
    },
  ],
},
{
  title: 'Time configuration',
  Icon: CalendarIcon,
  lines: [
    {
      label: 'Delegate time',
      boldValue: `${time} ${timeType}`,
    },
  ],
}];
  
export const lendSections = ({ price, listPeriod, ethPrice }:
{ price: number, minPeriod:number, maxPeriod: number, listPeriod:number, ethPrice:number })=>[{
  title: 'Price configuration',
  Icon: EthSummaryIcon,
  lines: [
    {
      label: 'Daily price',
      boldValue: `${price} ETH`,
      value: ` (${(ethPrice * price).toFixed(2)} USD)`,
      withDivider: true,
    },
  ],
},
{
  title: 'Time configuration',
  Icon: CalendarIcon,
  lines: [
    {
      label: 'Rent time',
      boldValue: `${listPeriod} Days`,
      withDivider: true,
    },
    /* {
      label: 'Min period',
      boldValue: `${minPeriod} Days`,
      withDivider: true,
    },
    {
      label: 'Max period',
      boldValue: `${maxPeriod} Days`,
      withDivider: true,
    }, */
  ],
}];
  
export const lendSecondSection = ({ price, listPeriod, ethPrice }:
{ price: number, listPeriod:number, ethPrice:number })=>[{
  lines: [
    {
      label: 'Lend profit',
      boldValue: `${price * listPeriod} ETH`,
      value: ` (${(ethPrice * price * listPeriod).toFixed(2)} USD)`,
      withDivider: true,
    },
    {
      label: 'It is calculated for the max period rent.',
    },
  ],
}];
export const lendThirdSection = ({ price, ethPrice }: { price: number, ethPrice:number })=>[{
  lines: [
    {
      label: 'Protocol fee',
      boldValue: `${(price * 0.25).toFixed(2)} ETH`,
      value: ` (${(ethPrice * 0.25 * price).toFixed(2)} USD)`,
      withDivider: true,
    },
    {
      label: '2.5% to the daily price',
    },
  ],
}];
  