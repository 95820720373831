//Crypto
import { useContractWrite } from 'wagmi';
import {
  UseContractWriteArgs,
  UseContractWriteConfig,
} from 'wagmi/dist/declarations/src/hooks/contracts/useContractWrite';

// Types
import { EndpointConfig, endpoints } from 'api/schema/types';

const useCryptoWrite = (
  url: EndpointConfig,
  options: UseContractWriteArgs & UseContractWriteConfig,
  customAddress?: string,
) => {
  // Config
  const { contractConfig, method } = endpoints[url];

  // Hooks
  const response = useContractWrite(
    customAddress
      ? { ...contractConfig, addressOrName: customAddress }
      : contractConfig, method, options,
  ); 

  return response;
};

export default useCryptoWrite;