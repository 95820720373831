import React from 'react';
import { Alert, Snackbar } from '@mui/material';

// Bussiness Components
import { CardDetails } from 'components/BussinesShared';

// Shared
import { Button, Flex } from 'components/Shared';

// Styles
import BaseStyles from './style';
import { Colors } from 'styles';

//Form
import { Control, useWatch } from 'react-hook-form';

//Utils
import { lendSecondSection, lendSections, lendThirdSection } from './utils';

// Hooks
import useDevice from 'hooks/useDevice';
import useConfirm from './useConfirm';
import { useParams } from 'react-router-dom';

interface SummaryProps {
  onClick: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  id: string;
}

const SummaryLend: React.FC<SummaryProps> = ({
  onClick,
  control,
  id,
}) => {
  const { isMobile } = useDevice();
  const [ listPeriod, minPeriod, maxPeriod, price, ethPrice] = useWatch({ control,
    name: [ 'listPeriod', 'minPeriod', 'maxPeriod', 'price', 'ethPrice'] });

  const { collection } = useParams();
  const { error, loading, onConfirm, setError } = useConfirm({
    id,
    listPeriod,
    price,
    collection,
    onClick,
  }, false);
  
  return (
    <Flex styles={BaseStyles.container}>
      <CardDetails
        sections={lendSections({ listPeriod, minPeriod, maxPeriod, price, ethPrice })}
      />

      <CardDetails
        sections={lendSecondSection({ listPeriod, price, ethPrice })}
        color="secondary"
      />

      <CardDetails
        sections={lendThirdSection({ price, ethPrice })}
        color="secondary"
        styles={{ border: `1px solid ${Colors.black}` }}
      />

      {!isMobile && (
        <Flex styles={BaseStyles.buttonContainer}>
          <Button
            size={isMobile ? 'none' : 'medium'}
            variant='contained'
            loading={loading}
            onClick={onConfirm}
            text="Confirm"
          />
        </Flex>
      )}

      {error && 
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={6000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
          >
            There has been an error, please try again
          </Alert>
        </Snackbar>
      }
    </Flex>
  );
};

export default SummaryLend;
