import { ButtonProps } from '@mui/material';
import { Colors } from 'styles';

const WidthSize = {
  none: null,
  small: 100,
  medium: 176,
  large: 216,
};

const HeightSize = {
  none: null,
  small: 40,
  medium: 40,
  large: 48,
};

const Background: CommonJSON<CommonJSON<string | undefined>> = {
  outlined: {
    primary: Colors.white,
    secondary: Colors.white,
  },
  contained: {
    primary: Colors.violetMain,
    secondary: Colors.white,
  },
  text: {
    primary: undefined,
    secondary: undefined,
  },
};

const Color: CommonJSON<CommonJSON<string | undefined>> = {
  outlined: {
    primary: Colors.violetMain,
    secondary: Colors.violetMain,
  },
  contained: {
    primary: Colors.white,
    secondary: Colors.violetMain,
  },
  text: {
    primary: Colors.violetMain,
    secondary: Colors.violetMain,
  },
};

interface ButtonStylesProps {
  isMobile: boolean;
  variant: ButtonProps['variant'];
  color: ButtonProps['color'];
  size: keyof typeof WidthSize;
  disabled: boolean;
}

const getStyles = ({ color, variant, size, disabled }: ButtonStylesProps) => ({
  //Containers
  button: {
    gap: 24,
    minWidth: size !== 'none' ? WidthSize[size] : undefined,
    width: size === 'none' ? '100%' : undefined,
    background: disabled ? Colors.extraExtraLightgray : color ? Background[variant!][color] : undefined,
    color: disabled ? Colors.white : color ? Color[variant!][color] : undefined,
    height: HeightSize[size],
    borderColor: Colors.primary,
    textTransform: 'none',
  },
});
    
export default getStyles;
    