import React from 'react';

// Wagmi
import { useConnect } from 'wagmi';

const useUser = () => {
  const [fakeConnect, setFakeConnect] = React.useState<boolean>(false);

  const { isConnected } = useConnect();

  const onFakeConnect = () => {
    setFakeConnect(prev => !prev);
  };

  return {
    isConnected: isConnected || fakeConnect,
    onFakeConnect,
  };
};

export default useUser;
