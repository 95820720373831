import React from 'react';

// Material
import { CircularProgress } from '@mui/material';

// Shared
import { Flex } from 'components/Shared';
import { Colors } from 'styles';

interface LoadingProps {
  color?:string;
}

const Loading: React.FC<LoadingProps> = ({ color = 'primary' }) => {
  return (
    <Flex styles={{
      width: '100%',
      alignSelf: 'center',
      justifySelf: 'center',
    }}
    >
      <CircularProgress style={{ color: color === 'primary' ? Colors.violetMain : Colors.white }} />
    </Flex>
  );
};

export default Loading;
