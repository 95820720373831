export const envKeys = {
  //Crypto
  alchemy: {
    id: process.env.REACT_APP_ALCHEMY_ID,
  },
  contract: {
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    wakeUp: process.env.REACT_APP_WAKEUP,
  },
  eth: {
    price: process.env.REACT_APP_API_ETH_PRICE,
  },
  // Apollo
  apolloClient: {
    uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  },
};