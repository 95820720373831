// Internal components
import ListNFts from './ListNFts';
import CollectionsRoot from './Root';

const paths = {
  empty: '/',
  list: '/:collectionId',
};

export const routes = [
  {
    path: paths.empty,
    Page: CollectionsRoot,
    exact: true,
  },
  {
    path: paths.list,
    Page: ListNFts,
    exact: true,
  },
];