
const WidthSize = {
  big: 176,
};

const HeightSize = {
  big: 176,
};

interface ButtonStylesProps {
  size?: keyof typeof WidthSize;
}

const getStyles = ({ size }: ButtonStylesProps) =>  ({
  //Containers
  root: {
    minWidth: size ? WidthSize[size] : undefined,
    minHeight: size ? HeightSize[size] : undefined,
  },
});

export default getStyles;
