import { MenuItem, Select } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

// Shared
import { Flex } from 'components/Shared';

interface SelectFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  name: string;
  controllerProps?: CommonJSON;
  selectFieldProps?: CommonJSON;
  options: ({ label: string; value: string })[];
  title?: string;
  error?: boolean;
}

const SelectField = (
  {
    name,
    controllerProps,
    selectFieldProps,
    control,
    options,
  }: SelectFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      {...controllerProps}
      render={({ field }) =>
        <Flex>
          {/* <InputLabel id="demo-simple-select-standard-label">{title || name}</InputLabel> */}
          <Select
            sx={{ minWidth: 100 }} 
            {...field}
            {...selectFieldProps} 
          >
            {options.map(({ value, label }, i) =><MenuItem key={value + i} value={value}>{label}</MenuItem>)}
          </Select>
        </Flex> 
      }
    />
  );
};

export default SelectField;