import React from 'react';

// Shared
import { Flex } from 'components/Shared';

// Hooks
import useStyles from 'hooks/useStyles';

//Styles
import { BackgroundEnum } from 'styles';
import getStyles from './style';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteElementProps<P = any, L = any> = Pick<RouteListProps<P, L>, 'Layout' | 'Page' | 'Background'>;

const RouteElement: React.FC<RouteElementProps> = ({
  Layout,
  Page,
  Background,
}) => {
  // Hooks
  const { BaseStyles } = useStyles({ getStyles });

  // Styles
  const backgroundStyle = Background === BackgroundEnum.difuminated
    ? BaseStyles.backgroundDifuminated
    : BaseStyles.backgroundGray;
  
  if (Layout) {
    return <Layout>
      <div style={{ ...BaseStyles.root, ...backgroundStyle }}>
        <Flex styles={BaseStyles.body}>
          <Page />
        </Flex>
      </div>
    </Layout>;
  }
  
  return (
    <Page />
  );
};

export default RouteElement;
