import { Colors } from 'styles';

const getStyles: StylesFn = (isMobile: boolean) => ({
  root: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    gap: 24,
    minHeight: isMobile ? 'calc(100vh - 100px)' : undefined,
  },
  headerContainer: {
    alignItems: 'flex-start',
    width: '100%',
    gap: 16,
    paddingBottom: 16,
  },
  textContainer: {
    width: '100%',
    flex: 1,
  },
  textColumn: {
  },
  container: {
    gap: 24,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flex: 1,
  },
  arrowStyles: {
    cursor: 'pointer',
    color: Colors.white,
    width: 32,
    height: 32,
  },

  // Buttons
  button: {
    width: isMobile ? '100%' : undefined,
  },
});

export default getStyles;
