import { Control, Controller } from 'react-hook-form';
import { TextField as TextFieldMaterial } from '@mui/material';

interface TextFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any, any>;
  name:string;
  controllerProps?: CommonJSON;
  textFieldProps?: CommonJSON;
  error?:boolean;
}

const TextField = ({ name, controllerProps, textFieldProps = {}, control }: TextFieldProps)=> {
  const { errorMessage } = textFieldProps;

  return (
    <Controller
      name={name}
      control={control}
      {...controllerProps}
      render={({ field }) => <>
        <TextFieldMaterial
          {...field}
          {...textFieldProps}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </>}
    />
  );
};

export default TextField;