import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { ReactNode } from 'react';

const StyledFlex = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

interface FlexProps {
  children?: ReactNode;
  styles?: Styles;
  row?: boolean;
}

export default function Flex({
  children,
  styles,
  row = false,
}: FlexProps) {
  const getStyles = () => {
    const result: Styles = {};

    if (row) {
      result.flexDirection = 'row';
    }

    return result;
  };
  const stylesParam = getStyles();
  
  return (
    <StyledFlex style={{ ...styles, ...stylesParam }}>
      {children}
    </StyledFlex>
  );
}