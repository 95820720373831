import React, { useEffect, useState } from 'react';

// Shared
import { CustomText, Flex } from 'components/Shared';

// Bussiness
import { CardWithImage as EmptyStateCard } from 'components/BussinesShared';

//MaterialUI
import { Box } from '@mui/system';
import { Alert, Snackbar, TextField, Tooltip } from '@mui/material';

//Styles
import { FontSize, FontWeight } from 'styles';
import BaseStyles from './styles';

// Hooks
import useCryptoRead from 'api/hooks/useCryptoRead';
import useCryptoWrite from 'api/hooks/useCryptoWrite';

//Mui
import { LoadingButton } from '@mui/lab';
import { BigNumber } from 'ethers';
import { useAccount } from 'wagmi';
import { Empty_state_wallet, MintNftIcon } from 'Images';

// Components
import { CustomCard } from 'components/Shared';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MintProps {
}

// eslint-disable-next-line max-len
const line1 = 'When you connect your wallet you will see not only your own NFTs, but also the ones that were delegated to you.';

const Mint: React.FC<MintProps> = () => {
  const [mintedTokens, setMintedTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { data: rainbowData } = useAccount();
  const { data: price } = useCryptoRead('getPrice');
  const { data: mintData, error: mintError, status, write: mint } = useCryptoWrite(
    'mint', 
    {
      args: { value: price },
      onError: () => { 
        setLoading(false);
        setError(true);
      },
      onSuccess: () => {
        setLoading(false);
        setError(false);
      },
    },
  );
  
  useEffect(() => {
    if (mintData) {
      const getTxMetadata = async () => {
        const nftIDReceipt = await mintData?.wait();
        const nftID = nftIDReceipt?.logs![1].topics![1];
        const newTokens :string[] = mintedTokens.concat([BigNumber.from(nftID).toString() || '0']);
        setMintedTokens(newTokens); 
        setLoading(false);
      };
      getTxMetadata();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintData]);

  if (!rainbowData?.address) {
    return (
      <Box paddingTop='32px'>
        <EmptyStateCard
          Icon={Empty_state_wallet}
          title="You need to connect your wallet"
          description={[line1]}
          callToActionProps={{ connect: true }}
        />
      </Box>
    );
  }

  return (
    <Flex styles={BaseStyles.root}>
      <CustomText fontSize={FontSize.xxxxlarge} style={BaseStyles.textStyles} fontWeight={FontWeight.extraBold}>
        MINT YOUR NFT
      </CustomText>
      <div style={BaseStyles.divider} />
      <CustomCard size='big' style={BaseStyles.mainCard}>
        <Box sx={BaseStyles.boxContainer}>
          <CustomText fontSize={FontSize.large} style={BaseStyles.textCardStyles} fontWeight={FontWeight.medium}>
            Please, mint your delegable non-fungible token
          </CustomText>
          <CustomCard size='medium' style={BaseStyles.cardButtonContainer}>
            <Box sx={BaseStyles.buttonCard} onClick={()=>{
              mint();
              setLoading(true);
            }}>
              <MintNftIcon/>
              <Box marginLeft='24px'>
                {loading ? 
                  <LoadingButton loading/>
                  : 
                  <>
                    <CustomText fontSize={FontSize.large} fontWeight={FontWeight.medium}>
                      Mint an NFT
                    </CustomText>
                    <CustomText fontSize={FontSize.small} fontWeight={FontWeight.default}>
                      Unrepeatable and delegable
                    </CustomText>
                  </>}
              </Box>
            </Box>
          </CustomCard>
          <Tooltip title="Minted tokens in this session" placement='bottom'>
            <TextField disabled helperText='Minted Tokens will appear here' value={mintedTokens.map(el=>el)}/>
          </Tooltip>
        </Box>
      </CustomCard>
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={6000}
          onClose={()=>setError(false)}
        >
          <Alert onClose={() => {setError(false);}} severity="error">
            There has been an error, please try again
          </Alert>
        </Snackbar>
      )
      }    
    </Flex>
  );
};

export default Mint;
