const getStyles: StylesFn = (isMobile: boolean) => ({
  //Containers
  root: {
    gap: '16px',
    alignItems: 'flex-end',
    width: '100%',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    width: '100%',
    placeItems: 'center',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
  sectionIcon: {
    padding: isMobile ? '60px 0px' : undefined,
    alignItems: isMobile ? 'center' : undefined,
  },
  sectionDescription: {
    padding: 24,
  },
  
  // Icons
  icon: {
    maxWidth: '100%',
  },

  // Custom Texts
  title: {
    padding: '8px 0px',
  },  
  description: {
    margin: '16px 0px',
  },

  // Buttons
  button: {
    width: isMobile ? '100%' : undefined,
  },
});

export default getStyles;
