import React from 'react';
import { useNavigate } from 'react-router-dom';

// Shared
import { CustomText, Flex, Filter, Button } from 'components/Shared';
import AsyncNftCard from '../AsyncNftCard';

// Hooks
import useFilter from 'hooks/useFilter';
import useStyles from 'hooks/useStyles';

// Config
import { redirect } from 'components/routes/config';

// Graphql
import { FilterCategories, filters } from 'graphql/filter.mocks';

// Images
import { Empty_state_wallet, PriceIcon } from 'Images';

// Styles
import BigNumber from 'bignumber.js';
import {
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
} from 'styles';
import getStyles from './style';

//Crypto
import { useAccount } from 'wagmi';

// Bussiness
import { CardWithImage as EmptyStateCard, CollectionCard } from 'components/BussinesShared';

//Context
import { useNftList } from 'contexts/nfts';

//Utils
import { generateKey } from 'utils/react';

// Api
import collections from 'api/schema/collections/mocks';

//Constants
import { EVM_CONSTANT } from 'utils/converter';
import { SECONDS_IN_24_HS } from 'utils/dateUtils';

// eslint-disable-next-line max-len
const line1 = 'When you connect your wallet you will see not only your own NFTs, but also the ones that were delegated to you.';
interface RentListProps {}

const RentList: React.FC<RentListProps> = () => {
  // Hooks
  const navigate = useNavigate();
  const { selected, onChangeFilter } = useFilter(FilterCategories.All);
  const { data: rainbowData } = useAccount();
  const { listedNfts: parsedData } = useNftList();

  const { BaseStyles } = useStyles({ getStyles });

  if (!rainbowData?.address) {
    return (
      <EmptyStateCard
        Icon={Empty_state_wallet}
        title="You need to connect your wallet"
        description={[line1]}
        callToActionProps={{ connect: true }}
      />
    );
  }
  
  return (
    <Flex styles={BaseStyles.sectionContainer}>
      <Flex styles={BaseStyles.collectionHeader} row>
        <CustomText
          fontFamily={FontFamily.secondary}
          fontSize={[FontSize.xlarge, FontSize.xxxxlarge]}
          fontWeight={FontWeight.extraBold}
          style={{ color: Colors.blackTitle }}
        >
          COLLECTIONS
        </CustomText>

        <Button
          text="See All"
          variant="outlined"
          size='small'
          onClick={() => navigate(redirect.collections())}
        />
      </Flex>

      <Flex styles={BaseStyles.collectionsList} row>
        <div style={BaseStyles.collectionsScrollable}>
          {collections.map(collection => (
            <div key={generateKey('collection-list')} style={{ paddingRight: 16 }}>
              <CollectionCard
                title={collection.name}
                subtitle={collection.description}
                images={collection.images}
                disableHover
                variant="vertical"
                onClickCard={() => navigate(redirect.collectionsList({ collectionId: collection.collectionId }))}
              />
            </div>
          ))}
        </div>
      </Flex>

      {/* NFT's to rent */}
      <CustomText
        fontFamily={FontFamily.secondary}
        fontSize={[FontSize.xlarge, FontSize.xxxxlarge]}
        fontWeight={FontWeight.extraBold}
        style={{ color: Colors.blackTitle }}
      >
        NFT's TO RENT
      </CustomText>

      <Flex row>
        {filters.map((filter: FilterCategories) => (
          <Filter
            key={generateKey('filter-rent-')}
            text={filter}
            selected={filter === selected}
            onClick={()=> onChangeFilter(filter)}
          />
        ))}
      </Flex>
      
      <div style={BaseStyles.nftList}>
        {parsedData.map(nft => (
          <AsyncNftCard
            {...nft}
            key={generateKey('nft-card-rent' + nft?.nftId)}
            index={nft?.index?.toString()}
            onClickIcon={() => navigate(redirect.nftDetails({
              index: nft.index.toString(),
              nftId: nft.tokenId.toString(),
              collection: nft?.collection!.toString(),
            }))}
            onClickButton={() => navigate(redirect.rent({
              nftId: nft.index.toString(),
              index: nft.tokenId.toString(),
              price: nft?.rentPriceSecond.toString(),
              collection: nft?.collection!.toString(),
            }))}
            labelButton="Rent"
            variant='horizontal'
            backgroundLabel={Colors.green}
            labelTextColor={Colors.blackLight}
            label={`${(BigNumber((nft.rentPriceSecond) / (EVM_CONSTANT)).times(SECONDS_IN_24_HS)).toFixed(6)} / Day`}
            IconLabel={PriceIcon}
            filteredNft={nft}
            rent
            disabledButton={nft.owner === rainbowData.address}
          />
        ))}
      </div>
    </Flex>

  );
};

export default RentList;
