//Icons
import { EthSummaryIcon } from 'Images';

export const delegateSection = ({ wallet }: { wallet: string })=>[{
  title: 'Address',
  Icon: EthSummaryIcon,
  lines: [
    {
      label: wallet,
    },
  ],
}];