import { ethAddressRegex } from 'utils/objectUtils';
import * as yup from 'yup';

export const schema = yup.object({
  step: yup.number().required(),
  price: yup.number().positive().moreThan(0).required(),
  delegation: yup.boolean().required(),
  wallet: yup.string().when('delegation', {
    is: true,
    then: yup
      .string()
      .required('Must enter a wallet address')
      .matches(ethAddressRegex, 'The address is in incorrect format.'),
  }),
  listPeriod: yup.number().typeError('Must be a number').test(
    'listPeriodValid',
    'List Period must be a positive number',
    (value, context) => {
      const { step, delegation } = context.parent;

      if (step < 2 || delegation) {
        return true;
      }

      if (!value || +value <= 0) {
        return false;
      }

      return true;
    },
  ),
  time: yup.number().typeError('Must be a number').test(
    'listPeriodValid',
    'Time must be a positive number',
    (value, context) => {
      const { step, delegation } = context.parent;

      if (step < 2 || !delegation) {
        return true;
      }

      if (!value || +value <= 0) {
        return false;
      }

      return true;
    },
  ),
  timeType: yup.string(),
  ethPrice: yup.number(),
}).required();

export type FieldsFormType = yup.InferType<typeof schema>;

export const defaultValues = {
  step: 0,
  price: 0.1,
  delegation: false,
  wallet: '',
  time: 0,
  timeType: 'Days',
  ethPrice: 0,
};
