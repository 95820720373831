import React, { Dispatch, SetStateAction } from 'react';

// Form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

// Bussiness components
import {
  NftCardSimple,
  SectionHeader,
  CardDetails,
} from 'components/BussinesShared';

// Shared Components
import {
  Button,
  CustomText,
  Flex,
  SelectField,
  TextField,
} from 'components/Shared';

// Mocks
import { nftFake } from 'graphql/Nfts.mocks';

// Styles
import BaseStyles from './style';
import { Colors, FontSize, FontWeight } from 'styles';

// Validations
import { defaultValues, FieldsFormType, schema } from './validation';

//Types
import { MAX_DAYS_TO_DELEGATE, selectOptions, SelectOptions } from 
  'pages/Lend/Steps/CardTimeConfigurationDelegate/CardTimeConfigurationDelegate';

//DateUtils
import { addDays, addHours, addMinutes, differenceInSeconds } from 'date-fns';

//Images
import { IconInformation } from 'Images';

//Text Utils
import { rentSummary, total } from './util';
import { useParams } from 'react-router-dom';

//Crypto
import BigNumber from 'bignumber.js';

//Constants
import { EVM_CONSTANT } from 'utils/converter';
import { SECONDS_IN_24_HS } from 'utils/dateUtils';
import useDevice from 'hooks/useDevice';

interface ConfigurationProps {
  onClick: () => void;
  onBack: () => void;
  setTime: Dispatch<SetStateAction<Date>>;
  setTimeType: Dispatch<SetStateAction<SelectOptions>>;
}

const rentDate = [{
  title: 'How long do you want to rent the NFT?',
}];

const Configuration: React.FC<ConfigurationProps> = ({
  onClick,
  onBack,
  setTime,
  setTimeType,
}) => {
  // Hooks
  const { isMobile } = useDevice();
  const { nftId, price } = useParams();
  const { control, formState: { errors }, watch } = useForm<FieldsFormType>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const time  = watch('time') || 0;
  const timeType  = watch('timeType');
  const priceIn24Hs = BigNumber(price || 0).times(SECONDS_IN_24_HS).dividedBy(EVM_CONSTANT).toNumber();
  const delegationTime = timeType === SelectOptions.Days ? addDays(new Date(), time) : 
    timeType === SelectOptions.Hours ? addHours(new Date(), time) :
      addMinutes(new Date(), time);

  const isValidTime = time! > 0 && errors && delegationTime < addDays(new Date(), MAX_DAYS_TO_DELEGATE);
  
  return (
    <Flex styles={BaseStyles.root}>
      <Flex styles={BaseStyles.mainBox}>
        <SectionHeader
          onBack={onBack}
          title="RENT CONFIGURATION"
          callToActionProps={{
            disabled: !isValidTime,
            text: 'Next',
            onClick: () => {
              setTime(delegationTime);
              setTimeType(timeType as SelectOptions);
              onClick();
            },
          }}
        >
          <Flex styles={BaseStyles.container} row>
            {/* First Column */}
            <NftCardSimple
              id={nftId!}
              subtitle={nftFake.collection}
            />
        
            {/* Second Column */}
            <Flex styles={BaseStyles.container}>
              <CardDetails
                sections={rentDate}
              >
                <Flex styles={BaseStyles.priceContainer}> 
                  <TextField
                    name='time'
                    control={control}
                    textFieldProps={{ 
                      sx: { ...BaseStyles.textFieldPrice },
                      label: 'Days/Hours/Minutes', type: 'number' }}
                    error={!isValidTime}
                  />
                  <SelectField
                    name='timeType'
                    title=' '
                    options={selectOptions}
                    control={control}
                  />
                </Flex>
                <Flex styles={BaseStyles.footerCardContainer} >
                  <IconInformation/>
                  <CustomText
                    fontWeight={FontWeight.regular}
                    fontSize={FontSize.small}
                    style={isValidTime ? {} : { color: Colors.red }}
                  >
                  The maximun period of time to rent is 7 days.
                  </CustomText>
                </Flex>
              </CardDetails>

              <CardDetails
                sections={rentSummary(new Date(delegationTime), priceIn24Hs)}
                total={total((
                  (BigNumber(price || 0).toNumber()) * differenceInSeconds(delegationTime, new Date())) / EVM_CONSTANT,
                )}
                color="secondary"
              />

              {!isMobile && (
                <Flex styles={BaseStyles.buttonContainer}>
                  <Button
                    disabled={!isValidTime}
                    text="Next"
                    onClick={() => {
                      setTime(delegationTime);
                      setTimeType(timeType as SelectOptions);
                      onClick();
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </SectionHeader>
      </Flex>
    </Flex>
  );
};

export default Configuration;
