// Material
import { AppBar, Toolbar, Container } from '@mui/material';
import Navigation from './navigation/Navigation';

const TopBar = () => {
  return (
    <AppBar position="sticky" elevation={0}  sx={{ background: 'linear-gradient(180deg, #8D39F3 0%, #5626EE 100%)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Navigation />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TopBar;
