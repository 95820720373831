// React
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { CardWithImage as EmptyStateCard, SectionHeader } from 'components/BussinesShared';
import AsyncNftCard from 'components/BussinesShared/AsyncNftCard';
import { CustomText, Flex } from 'components/Shared';

// Contexts
import { useNftList } from 'contexts/nfts';

// Config
import { redirect } from 'components/routes/config';

// Hooks
import useStyles from 'hooks/useStyles';
import { useAccount } from 'wagmi';

// Utils
import { generateKey } from 'utils/react';

// Styles
import getStyles from './styles';
import { EmptyStateNftIcon } from 'Images';
import collections from 'api/schema/collections/mocks';
import { FontFamily, FontSize, FontWeight } from 'styles';

interface ListNftsProps {
}

const ListNfts: React.FC<ListNftsProps> = () => {
  // Hooks
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const { listedNfts } = useNftList();
  const { data } = useAccount();
  const address = data?.address;

  const { BaseStyles } = useStyles({ getStyles });

  // Variables
  const nftFilteredByCollectionId = listedNfts.filter(item => (
    item.collection?.toLowerCase() === collectionId?.toLowerCase()
  ));
  const collectionEmpty = nftFilteredByCollectionId.length === 0;
  const collection = collections.find(item => item.collectionId === collectionId);

  return (
    <SectionHeader
      CustomTitle={() => (
        <Flex row>
          <CustomText
            fontFamily={FontFamily.secondary}
            fontWeight={FontWeight.extraBold}
            fontSize={[FontSize.xxxlarge, FontSize.xxxxlarge]}
          >
            Collection by
          </CustomText>
          &ensp;
          <CustomText
            fontFamily={FontFamily.secondary}
            fontWeight={FontWeight.regular}
            fontSize={[FontSize.xxxlarge, FontSize.xxxxlarge]}
          >
            {collection?.owner}
          </CustomText>
        </Flex>
      )}
      variant="secondary"
    >
      <Flex styles={{ ...BaseStyles.root, justifyContent: collectionEmpty ? 'center' : 'flex-start' }} row>

        {collectionEmpty && (
          <EmptyStateCard
            Icon={EmptyStateNftIcon}
            title="Collection Empty"
            description={['This collection does not have any NFT yet.']}
          />
        )}

        {nftFilteredByCollectionId.map((nft: NFTListing) => (
          <AsyncNftCard
            key={generateKey('nft-collection')}
            collection={collectionId}
            index={nft.index.toString()}
            nftId={nft.nftId}
            filteredNft={nft}
            labelButton="Rent"
            onClickButton={() => navigate(redirect.rent({
              nftId: nft.index.toString(),
              index: nft.tokenId.toString(),
              price: nft?.rentPriceSecond.toString(),
              collection: nft?.collection!.toString(),
            }))}
            onClickIcon={() =>navigate(redirect.nftDetails({
              index: nft.index,
              nftId: nft.nftId,
              collection: collectionId!,
            }))}
            disabledButton={nft.owner === address}
          />
        ))}
      </Flex>
    </SectionHeader>
  );
};

export default ListNfts;
