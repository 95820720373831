import React from 'react';

// Material
import { IconButton, IconButtonProps } from '@mui/material';

// Icons
import { IconStyles } from './styles';

interface CustomIconButtonProps extends IconButtonProps {
  img?: string;
  imageProps?:object;
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  img,
  imageProps,
  children,
  ...iconButtonProps
}) => (
  <IconButton color="primary" sx={IconStyles} {...iconButtonProps}>
    {img && <img src={img} {...imageProps} />}
    {children && children}
  </IconButton>
);

export default CustomIconButton;
