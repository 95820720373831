import { format } from 'date-fns';

export const rentSummary = (time:Date, pricePerDay: number)=> [{
  lines: [
    {
      label: 'NFT day price',
      boldValue: `${pricePerDay.toFixed(6)} ETH`,
      withDivider: true, 
    },
    {
      label: 'Rent time finishes',
      boldValue: `${format(time, 'PPpp')} Hs`,
      withDivider: true, 
    },
  ],
}];

export const total = (cost:number)=> ({
  label: 'SUBTOTAL',
  boldValue: `${cost.toFixed(5)} ETH`,
});