import React from 'react';
import { useNavigate } from 'react-router-dom';

// Material
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// Shared
import { CustomText, Flex } from 'components/Shared';
import Button, { CustomButtonProps } from 'components/Shared/Button';

// Hooks
import useDevice from 'hooks/useDevice';
import useStyles from 'hooks/useStyles';

// Styles
import { FontSize, FontWeight, Colors } from 'styles';
import getStyles from './style';

interface SectionHeaderProps {
  onBack?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CustomTitle?: React.FC<any>;
  title?: string;
  variant?: 'primary' | 'secondary';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RightComponent?: React.FC<any>;
  callToActionProps?: CustomButtonProps;
  children?: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  onBack,
  CustomTitle,
  title,
  children,
  RightComponent,
  variant = 'primary',
  callToActionProps,
}) => {
  // Hooks
  const { isMobile } = useDevice();
  const navigate = useNavigate();

  // Handlers
  const goBack = () => {
    navigate(-1);
  };

  const { BaseStyles } = useStyles({ getStyles });
  
  return (
    <Flex styles={BaseStyles.root}>
      <div style={{ width: '100%' }}>
        <Flex styles={BaseStyles.headerContainer} row={isMobile}>
          <KeyboardBackspaceIcon
            onClick={onBack || goBack}
            style={{ ...BaseStyles.arrowStyles, color: variant === 'primary' ? Colors.white : Colors.blackLight }}
          />

          <Flex styles={BaseStyles.textContainer} row>
            {title && (
              <CustomText
                fontSize={[FontSize.xxxlarge, FontSize.xxxxlarge]}
                fontWeight={FontWeight.extraBold}
                style={{ ...BaseStyles.textColumn, color: variant === 'primary' ? Colors.white : Colors.blackLight }}
              >
                {title}
              </CustomText>
            )}
            {CustomTitle && <CustomTitle />}

            <Flex styles={BaseStyles.textContainer}>
              {RightComponent && <RightComponent />}
            </Flex>
          </Flex>
        </Flex>

        {children && (
          <Flex styles={BaseStyles.container}>
            {children}
          </Flex>
        )}
      </div>

      {callToActionProps && isMobile && (
        <Button
          style={{ ...BaseStyles.button, ...callToActionProps.style }}
          size="large"
          {...callToActionProps}
        />
      )}
    </Flex>
  );
};

export default SectionHeader;
