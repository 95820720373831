// Material
import { createTheme } from '@mui/material';

// Styles
import { Colors } from './colors';

export enum BackgroundEnum {
  gray = 'gray',
  difuminated = 'difuminated',
}

declare module '@mui/material' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.violetMain,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.greenBright,
      contrastText: Colors.violetMain,
    },
    background: {
      default: Colors.extraLightgray,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          height: '40px',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: Colors.violetMain,
        colorSecondary: Colors.white,
      },
    },	
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

export default theme;
