import React from 'react';

const useFilter = <T = string>(defaultValue: T) => {
  // States
  const [selected, setSelected] = React.useState<T>(defaultValue); 

  // Handler
  const handleChangeFilter = (value: T) => {
    setSelected(value);
  };

  return {
    selected,
    onChangeFilter: handleChangeFilter,
  };
};

export default useFilter;