/* eslint-disable no-console */
// Material
import { Button } from '@mui/material';

// Hooks
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Shared
import TextFieldCustom from 'components/Shared/TextField/TextField';
import { SelectField } from 'components/Shared';
import Flex from 'components/Shared/Flex/Flex';

// Validations
import { FieldsFormType, schema, defaultValues } from './Validation';

//Styles
import { flexStyles, formStyles } from './style';

const ExampleForm = () => {
  const { handleSubmit, control, getValues, formState: { errors }  } = useForm<FieldsFormType>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<FieldsFormType> = data => {
    console.log(getValues(), data);
  };
  
  return (
    <Flex>
      <form onSubmit={handleSubmit(onSubmit)} style={formStyles}>
        <Flex styles={flexStyles}>
          {/* Fields */}
          <TextFieldCustom name='TextName' control={control} textFieldProps={{ label: 'Label' }} />
          <TextFieldCustom name='Age' control={control}/>
          <TextFieldCustom name='Email' control={control}/>
          <SelectField
            name='Gender'
            options={[{ value: 'Male', label: 'Male' }, { value: 'Female', label: 'Female' }]}
            control={control}
          />

          {/* Buttons */}
          <Button type="submit" variant={'contained'}>
            Submit
          </Button>
        </Flex>
      </form>

      <Flex>
        {JSON.stringify(errors)}
      </Flex>
    </Flex>
  );
};

export default ExampleForm;
