import React from 'react';

// Shared
import { Flex, Stepper } from 'components/Shared';
import { CardWithImage as EmptyStateCard, SectionHeader } from 'components/BussinesShared';

// Bussiness
import { NftCardSimple } from 'components/BussinesShared';

//Components
import CardTimeConfiguration from 'pages/Lend/Steps/CardTimeConfigurationDelegate';

// Steps
import SummaryDelegation from './Steps/Summary/SummaryDelegation';
import SummaryLend from './Steps/Summary/SummaryLend';
import CardPriceConfiguration from './Steps/CardPriceConfigurationDelegate';
import CardTimeConfigurationLend from './Steps/CardTimeConfigurationLend';

//Types
import { MyNFTsTabs } from 'pages/MyNfts/types';

// Images
import { ImgFeedbackLend, Img_welcome_lend } from 'Images';

//Material
import { Box } from '@mui/system';

//Form utils
import { FieldsFormType, schema, defaultValues } from './Validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//Utils
import { useNavigate, useParams } from 'react-router-dom';

//Hooks
import useStepper from 'hooks/useStepper';
import useDevice from 'hooks/useDevice';
import useStyles from 'hooks/useStyles';

//Navigation
import { redirect } from 'components/routes/config';

// Internal hook
import useConfirm from './Steps/Summary/useConfirm';

// Styles
import getStyles from './style';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LendProps {
}

const fakeData = {
  title: 'NFT Name',
  collection: 'Collection',
};

const steps = [
  'Price',
  'Time',
  'Summary',
];

enum LendSteps {
  WELCOME = 0,
  PRICE_CONFIGURATION,
  TIME_CONFIGURATION,
  SUMMARY,
  CONGRATULATIONS,
}

// eslint-disable-next-line max-len
const line1 = 'You can lend your NFT easily: Configure the rent time and price. Wait until someone rent your NFT. Claim your rewads and enjoy!';
const line2 = 'As long as they haven`t rent your NFT, you will be able to edit or unlist the NFT. ';
const line3 = 'Also, I you want, you can free delegate an a especific address.';

const Lend: React.FC<LendProps> = () => {
  // Hooks
  const { isMobile } = useDevice();
  const { nftId = '', collection } = useParams();
  const { currentStep, goToNextStep, goToPrevStep } = useStepper(4);
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<FieldsFormType>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { BaseStyles } = useStyles({ getStyles });

  // Watches
  const isDelegation = watch('delegation') || defaultValues.delegation;
  const listPeriodWatched = watch('listPeriod') || 0;
  const priceWatched = watch('price') || defaultValues.price;
  const walletWatched = watch('wallet') || defaultValues.wallet;
  const timeTypeWatched = watch('timeType') || defaultValues.timeType;
  const timeWatched = watch('time') || defaultValues.time;

  const { onConfirm, loading } = useConfirm({
    id: nftId,
    price: priceWatched,
    listPeriod: listPeriodWatched,
    wallet: walletWatched,
    time: timeWatched,
    timeType: timeTypeWatched,
    onClick: goToNextStep,
    collection,
  }, isDelegation);
  
  // console.log(errors, getValues());

  React.useEffect(() => {
    setValue('step', currentStep);
  }, [currentStep, setValue]);

  const stepsInfo = {
    [LendSteps.WELCOME]: {
      title: '',
      buttonLabel: 'Next',
    },
    [LendSteps.PRICE_CONFIGURATION]: {
      title: 'PRICE CONFIGURATION',
      buttonLabel: 'Next',
    },
    [LendSteps.TIME_CONFIGURATION]: {
      title: 'TIME CONFIGURATION',
      buttonLabel: 'Next',
    },
    [LendSteps.SUMMARY]: {
      title: 'SUMMARY',
      buttonLabel: 'Confirm',
    },
    [LendSteps.CONGRATULATIONS]: {
      title: '',
      buttonLabel: 'Next',
    },
  };

  const handleNextStep = () => {
    if (currentStep === LendSteps.SUMMARY) {
      onConfirm();
      
      return;
    }
    goToNextStep();
  };

  const { title, buttonLabel = 'Next' } = stepsInfo[currentStep as LendSteps];

  return (
    <Box>
      {currentStep === LendSteps.WELCOME && 
          <Box paddingTop='32px'>
            <EmptyStateCard
              Icon={Img_welcome_lend}
              title="Welcome!"
              description={[
                line1,
                line2,
                line3,
              ]}
              callToActionProps={{
                text: 'Next',
                onClick: handleSubmit(handleNextStep),
                loading,
              }}
            />
          </Box>
      }

      {currentStep > LendSteps.WELCOME && currentStep < 4 && (
        <Flex>
          <Flex styles={{ width: isMobile ? '100%' : undefined }}>
            <SectionHeader
              title={title}
              RightComponent={() =>
                isMobile ? null : <Stepper steps={steps} activeStep={currentStep - 1} />
              }
              onBack={goToPrevStep}
              callToActionProps={{
                text: buttonLabel,
                onClick: handleSubmit(handleNextStep), 
              }}
            >
              <Flex styles={BaseStyles.body} row>
                {!isMobile && (
                  <NftCardSimple
                    id={nftId}
                    subtitle={fakeData.collection}
                  />
                )}

                <Flex styles={BaseStyles.stepperSection}>
                  {isMobile && <Stepper steps={steps} activeStep={currentStep - 1} />}

                  {isMobile && (
                    <NftCardSimple
                      id={nftId}
                      subtitle={fakeData.collection}
                    />
                  )}

                  {currentStep === LendSteps.PRICE_CONFIGURATION && (
                    <CardPriceConfiguration 
                      setValue={setValue} 
                      control={control} 
                      errors={errors}
                      goToNextStep={handleSubmit(handleNextStep)}
                    />
                  )}

                  {currentStep === LendSteps.TIME_CONFIGURATION && (
                    isDelegation
                      ? (
                        <CardTimeConfiguration
                          id={nftId}
                          control={control}
                          errors={errors}
                          goToNextStep={handleSubmit(handleNextStep)}
                        />
                      )
                      : (
                        <CardTimeConfigurationLend
                          id={nftId}
                          control={control}
                          errors={errors}
                          goToNextStep={handleSubmit(handleNextStep)}
                        />
                      )
                  )}

                  {currentStep === LendSteps.SUMMARY && (
                    isDelegation
                      ? (
                        <SummaryDelegation
                          onClick={goToNextStep}
                          control={control}
                          id={nftId}
                        />
                      ) : (
                        <SummaryLend
                          onClick={goToNextStep}
                          control={control}
                          id={nftId}
                        />
                      )
                  )}
                </Flex>
              </Flex>
            </SectionHeader>
          </Flex>
        </Flex>
      )}

      {currentStep === 4 && (
        <Box paddingTop='32px'>
          <EmptyStateCard
            Icon={ImgFeedbackLend}
            title={isDelegation ? 'Yey! Your NFT has been successfully delegated' :
              'Yey! Your NFT is now available to be rented'}
            description={isDelegation
              ? []
              : ['You can see the details of the rent in the My NFTs section.']}
            callToActionProps={{
              text: 'See My NFTs', 
              onClick: () => navigate(redirect.myNfts({ activeTab: MyNFTsTabs.DEFAULT })),
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Lend;
