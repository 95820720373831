import format from 'date-fns/format';

export const SECONDS_IN_24_HS = 86400;

export const getFormattedDelegatedDate = (delegatedDate:number)=>
  new Date() < new Date(delegatedDate) ? format(new Date(delegatedDate), 'PPpp') : 'Not Delegated';  

export const getNowDateParsed = (expires: string)=>{
  return expires.length > 11 ?
    new Date().getTime() : (new Date().getTime() / 1000);
};
