const BaseStyles: Styles = {
  //Containers
  rootTitle: {
    paddingBottom: 18,
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
  },
  rootLine: {
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
  },
};
  
export default BaseStyles;
  