import { minHeightTobBar } from 'styles/constants';

const BaseStyles = {
  root: {
    width: '100%',
    minHeight: `calc(100vh - ${minHeightTobBar})`,
    justifyContent: 'flex-start',
    marginTop: '32px',
  },
  linkStyles: {
    textTransform: 'underline',
  },
};

export default BaseStyles;
