import React from 'react';

// Shared
import {
  CustomCard,
  CustomText,
  Flex,
  TextField,
  Button,
  SelectField,
} from 'components/Shared';
import { CardDetails } from 'components/BussinesShared';

//Form
import { useWatch } from 'react-hook-form';

//Images
import { IconInformation } from 'Images';

//Types
import { Control, FieldErrorsImpl } from 'react-hook-form/dist/types';

//Date Utils
import { addDays, addHours, addMinutes } from 'date-fns';

// Hooks
import useDevice from 'hooks/useDevice';

//Utils
import { delegateSection } from './utils';

// Styles
import BaseStyles from './style';
import { FontSize, FontWeight } from 'styles';

interface CardTimeConfigurationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>,
  errors: FieldErrorsImpl,
  id: string,
  goToNextStep: ()=>void
}

export enum SelectOptions {
  Days = 'Days',
  Hours = 'Hours',
  Minutes = 'Minutes',
}

export const selectOptions = [{ value: SelectOptions.Days, label: SelectOptions.Days },
  { value: SelectOptions.Hours, label: SelectOptions.Hours },
  { value: SelectOptions.Minutes, label: SelectOptions.Minutes }];

export const MAX_DAYS_TO_DELEGATE = 7;

const CardTimeConfiguration: React.FC<CardTimeConfigurationProps> = ({ control, errors, goToNextStep }) => {
  const [timeType, time = 0, wallet] = useWatch({ control, name: ['timeType', 'time', 'wallet'] });
  
  // Hooks
  const { isMobile } = useDevice();

  const delegationTime = timeType === SelectOptions.Days ? addDays(new Date(), time) : 
    timeType === SelectOptions.Hours ? addHours(new Date(), time) :
      addMinutes(new Date(), time);

  const isValidTime = time > 0 && errors && delegationTime < addDays(new Date(), MAX_DAYS_TO_DELEGATE); 
  
  return (
    <>
      <CardDetails
        sections={delegateSection({ wallet })}
        color="primary"
      />
      <CustomCard size='medium' style={BaseStyles.customCardStyles}>
        <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.medium}>
        Chose the period of time to delegate
        </CustomText>
        <Flex styles={BaseStyles.priceContainer}> 
          <TextField
            name='time'
            control={control}
            textFieldProps={{ 
              sx: { ...BaseStyles.textFieldPrice },
              label: 'Days/Hours/Minutes',
              type: 'number',
              errorMessage: errors.time?.message,
            }}
          />
          <SelectField
            name='timeType'
            title=''
            options={selectOptions}
            control={control}
            selectFieldProps={
              { 
                sx: { ...BaseStyles.textFieldPrice },
                label: 'Days/Hours/Minutes',
                errorMessage: errors.timeType?.message,
              }
            }
          />
        </Flex>
        <Flex styles={BaseStyles.footerCardContainer} >
          <IconInformation/>
          <CustomText 
            fontWeight={FontWeight.regular}
            fontSize={FontSize.small}
          >
            The maximun period of time to delegate is 7 days.
          </CustomText>
        </Flex>
      </CustomCard>

      {!isMobile && (
        <Button
          size={isMobile ? 'none' : 'medium'}
          text='Next'
          disabled={!isValidTime}//Check how to use validation with defaultValues
          onClick={goToNextStep}
        />
      )}
    </> 
  );
};

export default CardTimeConfiguration;
