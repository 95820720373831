
const getStyles: StylesFn = isMobile => ({
  //Containers
  root: {
    gap: 24,
    width: isMobile ? '100%' : undefined,
  },
  timeLeftCard: {
    textAlign: isMobile ? 'center' : undefined,
  },
});
    
export default getStyles;
    