import React from 'react';
import { useNavigate } from 'react-router-dom';

// Business
import { CardWithImage } from 'components/BussinesShared';

// Images
import { NftBlocks } from 'Images';

// Config
import { redirect } from 'components/routes/config';

// Hooks
import useStyles from 'hooks/useStyles';

// Styles
import getStyles from 'pages/NftDetails/style';

interface AvailableProps {
  nft: NFT;
}

const Available: React.FC<AvailableProps> = ({
  nft,
}) => {
  // Hooks
  const { BaseStyles } = useStyles({ getStyles });
  const navigate = useNavigate();

  return (
    <CardWithImage
      Icon={NftBlocks}
      title="You can lend or delegate this NFT"
      description={
        ['Do not let your NFT sleep, you can lend or delegate it and have a profit or add points.']
      }
      iconSize="small"
      variant="vertical"
      callToActionProps={{
        style: BaseStyles.buttonContainer,
        text: 'Delegate',
        onClick: () => navigate(redirect.lend({ nftId: nft.nftId })),
      }}
    />

  );
};

export default Available;
