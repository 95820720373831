import { Box, styled } from '@mui/system';
import { Colors } from 'styles/colors';

export const flexStyles = { 
  background: Colors.white,
  padding: 20,
  width: 410,
  height: 491,
  boxShadow: `0px 3px 6px rgba(${Colors.blackLight}, 0.15)`,
  borderRadius: '6px',
  alignItems: 'flex-start',
  position: 'relative',
};

export const flexStylesMobile = { 
  background: Colors.white,
  padding: 8,
  width: '100%',
  maxWidth: 440,
  height: 132,
  boxShadow: `0px 3px 6px rgba(${Colors.blackLight}, 0.15)`,
  borderRadius: '6px',
  alignItems: 'flex-start',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
};

export const boxMobileButtonStyles = { 
  width: '65%',
  marginLeft: '8px',
};

export const boxButtonStyles = { 
  width: '100%',
};

export const StyledImg = styled('img')({
  alignSelf: 'center', 
  marginBottom: '2%',
});

export const buttonsFlexStyles = { 
  display: 'flex', 
  width: '100%',
  justifyContent: 'space-between', 
  alignSelf: 'center', 
  marginTop: '2%',
  flexDirection: 'row',
  gap: '16px',  
};
  
export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '8px 16px',
  gap: '10px',
  position: 'absolute',
  width: '81px',
  height: '30px',
  left: '0px',
  top: '0px',
  background: Colors.blackLight,
  borderRadius: '0px 0px 16px 0px',
  color: Colors.green, 
});
