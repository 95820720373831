import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';

// Material
import { Button, ButtonProps } from '@mui/material';

// Hooks
import useStyles from 'hooks/useStyles';

// Shared Components
import { CustomText, Loading } from 'components/Shared';

//Types
import { CustomTextProps } from '../CustomText/CustomText';

// Styles
import { FontWeight } from 'styles/font';
import getStyles from './style';

export interface CustomButtonProps extends Omit<ButtonProps, 'size'> {
  text?: string;
  textProps?: CustomTextProps;
  connect?: boolean;
  disconnect?: boolean;
  size?: 'none' | 'small' | 'medium' | 'large';
  loading?: boolean;
}

const ButtonCustom: React.FC<CustomButtonProps> = ({
  text,
  color = 'primary',
  size = 'medium',
  variant = 'contained',
  disabled = false,
  textProps,
  connect = false,
  disconnect = false,
  style,
  loading,
  children,
  ...buttonProps
}) => {
  const { BaseStyles } = useStyles({
    getStyles: isMobile => getStyles({
      isMobile,
      color,
      variant,
      size,
      disabled,
    }),
  });

  const getLoadingColor = () => {
    if (variant === 'contained') {
      return color === 'primary' ? 'secondary' : 'primary';
    }
    
    return 'primary';
  };
 
  if (connect) {
    return (
      <div className={color === 'secondary' ? 'connect-button secondary' : 'connect-button'}>
        <ConnectButton accountStatus={'avatar'} />
      </div>
    );
  }

  if (disconnect) {
    return (
      <div className={color === 'secondary' ? 'disconnect-button secondary' : 'disconnect-button'}>
        <ConnectButton accountStatus={'avatar'} />
      </div>
    );
  }
  
  return (
    <Button 
      variant={variant}
      disabled={disabled || loading}
      disableElevation
      style={{ ...BaseStyles.button, ...style }}
      {...buttonProps}
    >
      {loading
        ? <Loading color={getLoadingColor()} />
        : (
          <>
            {text && (
              <CustomText fontWeight={FontWeight.medium} {...textProps}>
                {text}
              </CustomText>
            )}   
            {children && children}
          </>
        )}
    </Button>
  );
};

export default ButtonCustom;
