const getStyles = (isMobile: boolean) =>  ({
  //Containers
  sectionContainer: {
    alignItems: 'flex-start',
    width: '100%',
    gap: 16,
  },
  nftList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 400px))',
    gap: 24,
    width: '100%',
  },
  collectionsList: {
    width: isMobile ? '100%' : undefined,
  },
  collectionsScrollable: {
    overflowX: 'auto',
    width: '100%',
    whiteSpace: 'nowrap',
    display: 'inherit',
    padding: '16px 0px',
  },
  collectionHeader: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
});

export default getStyles;
