import { Colors } from 'styles';

const BaseStyles = {
  dividerStyles: {
    borderTop: `1px solid ${Colors.black}`,
    width: '100%',
    height: '0px',
  },
};

export default BaseStyles;
