import { Colors } from 'styles';

const BaseStyles = {
  root: { 
    alignItems: 'flex-start', 
  },
  mainCard: { 
    margin: '32px 0 32px 0', 
    display: 'flex', 
    justifyContent: 'center', 
    alignSelf: 'center',  
  },
  textStyles: {
    paddingTop: '32px',
    alignItems: 'flex-start',
  },
  textCardStyles: {
    padding: '20px 0 20px 0',
    alignItems: 'flex-start',
  },
  divider: {
    borderBottom: `1px solid ${Colors.black}`, 
    width: '100%', 
  },
  boxContainer: { 
    padding: '32px 0 32px 0', 
    alignSelf: 'center', 
    display: 'flex', 
    width: '80%', 
    flexDirection: 'column',
  },
  cardButtonContainer: {
    cursor: 'pointer', 
    marginBottom: '32px', 
    display: 'flex',
    justifyContent: 'center', 
    width: '300px', 
    height: '96px', 
    backgroundColor: Colors.violetExtraLight, 
    alignSelf: 'center', 
  },
  buttonCard: {
    padding: '24px',
    display: 'flex', 
  },
};

export default BaseStyles;
