import { Colors } from '../../../styles/colors';
import { FontSize, FontWeight } from '../../../styles/font';

export const selectedStyles = { 
  background: Colors.violetLigt,
  border: `1.5px solid ${Colors.white}`,
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  textTransform: 'Capitalize',
  margin: '8px 8px 0 0',
  '&:hover': {
    background: Colors.violetLigt,
  },
};

export const notSelectedStyles = { 
  background: Colors.white,
  border: `1.5px solid ${Colors.violetLigt}`,
  borderRadius: '10px',
  color: Colors.blackLight,
  textTransform: 'Capitalize',
  margin: '8px 8px 0 0',
  '&:hover': {
    background: Colors.white,
  },
};

export const selectedTextStyles = { 
  color: Colors.white,
  textTransform: 'Capitalize',
  fontSize: FontSize.small,
  fontWeight: FontWeight.default,
};

export const notSelectedTextStyles = { 
  color: Colors.blackLight,
  textTransform: 'Capitalize',
  fontSize: FontSize.small,
  fontWeight: FontWeight.medium,
};
