import React from 'react';

// Utils
import { sendRequest } from 'utils/apiUtils';

interface Options {
  enabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useRest = <UseRestResponse = any>(url: string, options: Options) => {
  // Refs
  const loading = React.useRef(false);

  // States
  const [data, setData] = React.useState<CommonJSON | null>(null);
  const [error, setError] = React.useState<RestError | null>(null);
  
  // Effects
  React.useEffect(() => {
    const callRequest = async () => {
      try {
        loading.current = true;
        const res = await sendRequest(url);
        if (res) {
          setData(res);
        }
        loading.current = false;
      } catch (e: unknown) {
        const err = e as RestError;
        loading.current = false;
        setError(err);
      }      
    };

    if (options.enabled) {
      callRequest();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.enabled]);

  return {
    data: data as UseRestResponse,
    error,
    loading: loading.current,
  };
};

export default useRest;