enum Breakpoints {
  mobile = 0,
  // tablet = 640,
  // laptop = 1024,
  desktop = 640,
}

export {
  Breakpoints,
};
