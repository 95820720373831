const getStyles: StylesFn = isMobile => ({
  //Containers
  root: {
    // padding: '64px 12px',
  },
  nftContainer: {
    width: isMobile ? '100%' : undefined,
  },
  body: {
    gap: 24,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flex: 1,
  },
  buttonContainer: {
    width: isMobile ? '100%' : undefined,
  },
});
  
export default getStyles;
  