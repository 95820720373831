import React, { useState } from 'react';

// Hooks
import useStepper from 'hooks/useStepper';

// Steps
import {
  Configuration,
  Congratulations,
  Welcome,
  Summary,
} from './Steps';

//Crypto
import { useAccount } from 'wagmi';

//Images
import { Empty_state_wallet } from 'Images';

//Bussiness Shared
import { CardWithImage as EmptyStateCard } from 'components/BussinesShared';

//Material UI
import { Box } from '@mui/material';

// Enums
import { SelectOptions } from 'pages/Lend/Steps/CardTimeConfigurationDelegate/CardTimeConfigurationDelegate';

interface FormProps {
}

enum RentSteps {
  WELCOME = 0,
  CONFIGURATION,
  SUMMARY,
  CONGRATULATIONS,
}

// eslint-disable-next-line max-len
const line1 = 'When you connect your wallet you will see not only your own NFTs, but also the ones that were delegated to you.';

const Form: React.FC<FormProps> = () => {
  const { data: rainbowData } = useAccount();
  const {
    currentStep,
    goToNextStep,
    goToPrevStep,
  } = useStepper(4);
  const [time, setTime] = useState(new Date);
  const [timeType, setTimeType] = useState<SelectOptions>(SelectOptions.Days);

  const getStep = () => {
    switch (currentStep) {
      case RentSteps.WELCOME:
        return <Welcome
          onClick={goToNextStep}
        />;
      case RentSteps.CONFIGURATION:
        return (
          <Configuration
            onClick={goToNextStep}
            onBack={goToPrevStep}
            setTime={setTime}
            setTimeType={setTimeType}
          />
        );
      case RentSteps.SUMMARY:
        return (
          <Summary
            onClick={goToNextStep}
            onBack={goToPrevStep}
            time={time}
            timeType={timeType}
          />
        );
      case RentSteps.CONGRATULATIONS:
        return (
          <Congratulations />
        );
      default:
        return <Welcome onClick={goToNextStep} />;
    }  
  };
  
  if (!rainbowData?.address) {
    return (
      <Box paddingTop='32px'>
        <EmptyStateCard
          Icon={Empty_state_wallet}
          title="You need to connect your wallet"
          description={[line1]}
        />
      </Box>
    );
  }
  
  return <>
    {getStep()}
  </>;
};

export default Form;
