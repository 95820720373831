const getStyles = (isMobile: boolean) =>  ({
  //Containers
  root: {
    justifyContent: 'flex-end',
    width: '100%',
  },
  container: {
    overflowX: 'auto',
    width: isMobile ? '100%' : undefined,
    whiteSpace: 'nowrap',
    padding: '8px 0px',
  },
});

export default getStyles;
