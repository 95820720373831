import React, { useCallback } from 'react';
import { useAccount } from 'wagmi';
import { useQuery } from '@apollo/client';

// Api
import useCryptoRead from 'api/hooks/useCryptoRead';
import { getNftParsed } from 'api/utils';
import { rentInformationsOwner, rentInformationsUser } from 'graphql/subgraphQueries';

// Enums
import { NftStatus } from 'enums';

//Date Utils
import { getNowDateParsed } from 'utils/dateUtils';

interface NftsContext {
  ownNfts: NFTListing[];
  delegatedNfts: NFTListing[];
  receivedNfts: NFTListing[];
  allNfts: NFTListing[];
  listedNfts: NFTListing[];
  isLoading: boolean;
  error: null | Error;
  isListed: (nftId: string) => boolean;
}

const initialValue = {
  isLoading: false,
  error: null,
  ownNfts: [],
  receivedNfts: [],
  allNfts: [],
  delegatedNfts: [],
  listedNfts: [],
  isListed: () => false,
};

const POLLING_INTERVAL = 4000;
// TODO: improve this. quick solution!
const setStatus = (list: (NFT | NFTListing)[], status: NftStatus) => (
  list.map(item => ({ ...item, status }))
);

// TODO: remove it after deploy new contract
const blacklist = [91, 126, 129, 133];

/**
 * Nfts Context Creation.
 */
export const nftsContext = React.createContext<NftsContext>(initialValue);

const useNftsProvider = (): NftsContext => {
  // Hooks
  const {
    error: errorIpfs,
    isLoading: loadingIpfs,
    data: nftList,
  } = useCryptoRead<NFTRaw[]>('list');
  const { data: rainbowData } = useAccount();

  // Queries
  // Delegated nfts. Consultar si esta es delegado por tokenId y expiration > now
  const { loading: loadingUserinfoOwner, data: dataRentUser } = useQuery<GetRentInformationUserResponse>(
    rentInformationsUser,
    {
      variables: {
        user: rainbowData?.address,
      },
      skip: !rainbowData?.address,
      fetchPolicy: 'network-only',
      pollInterval: POLLING_INTERVAL,
    },
  );

  // My own nfts
  const { loading: loadingRentinfoOwner, data: dataRentInfo } = useQuery(
    rentInformationsOwner,
    {
      variables: {
        owner: rainbowData?.address,
      },
      skip: !rainbowData?.address,
      fetchPolicy: 'network-only',
      pollInterval: POLLING_INTERVAL,
    },
  );

  const isLoading = loadingUserinfoOwner || loadingRentinfoOwner || loadingIpfs;
  // Memos
  const nftsParsed = React.useMemo(() => {
    if (!nftList || isLoading) {
      return [];
    }

    return nftList.reduce((acc: NFTListing[], nft) => {
      if (!nft) {
        return acc;
      }

      const nftParsed = getNftParsed(nft);
      if (nftParsed) {
        acc.push(nftParsed);
      }

      return acc;
    }, []);
  }, [isLoading, nftList]);
  
  const receivedNfts = React.useMemo(() => {
    if (!nftList || !dataRentUser?.rentInformations || isLoading) {
      return [];
    }
    const data = dataRentUser.rentInformations.filter(
      (e: NFTRaw) => parseInt(e.expires) > Math.floor(getNowDateParsed(e.expires)),
    );
      
    return setStatus((data as NFTRaw), NftStatus.DELEGATE);
  }, [dataRentUser?.rentInformations, isLoading, nftList]);

  //Aaca seria mostrar todos los dataRentInfo, chequeando si esta en lista listado/rentado o lista delegados
  const getListingNftInfo = useCallback((nft: NFTRaw)=>{
    const filteredNft = nftsParsed.filter((el: NFTRaw)=> (
      el.nftId === nft.nftId && nft.collection.toLowerCase() === el.collection.toLowerCase()
    ));
    const statusLended = filteredNft.find(nftFiltered=>nftFiltered.status === NftStatus.LENDED);
    const nowDateParsed = nft.expires.length > 11 ?
      new Date().getTime() : (new Date().getTime() / 1000);
    const statusDelegated = parseInt(nft.expires) > Math.floor(nowDateParsed) && !statusLended;
    const statusListed = filteredNft.find(nftFiltered=>nftFiltered.status === NftStatus.LISTED);

    const getStatus = () => {
      if (statusDelegated) {
        return NftStatus.DELEGATE;
      }

      if (statusLended) {
        return NftStatus.LENDED;
      }

      if (statusListed) {
        return NftStatus.LISTED;
      }

      return NftStatus.AVAILABLE;
    };

    return { 
      status: getStatus(), 
      index: statusLended?.index || statusListed?.index, 
      listing: statusLended || statusListed || statusDelegated || {},
    };
  }, [nftsParsed]);

  const ownNfts = React.useMemo(() => {
    if ( !dataRentInfo?.rentInformations || isLoading) {
      return [];
    }

    return dataRentInfo.rentInformations.map((nftOwn: NFTRaw)=>{
      
      const nftListingInfo = getListingNftInfo(nftOwn);
      nftOwn.status = nftListingInfo.status;
      nftOwn.nftId = nftOwn.id.split('/')[1];
      //nftOwn.index = nftListingInfo.index;

      return { ...nftOwn, ...nftListingInfo.listing  };
    });

  }, [dataRentInfo?.rentInformations, getListingNftInfo, isLoading]);

  const delegatedNfts = React.useMemo(() => {
    if (!nftList || !receivedNfts || isLoading) {
      return [];
    }
    const acc: NFTListing[] = [];
    receivedNfts.map((nftOwn: NFTRaw)=>{
      const nftListingInfo = getListingNftInfo(nftOwn);
      nftOwn.nftId = nftOwn.nftId;
      nftOwn.status = nftListingInfo.status;
      nftOwn.index = nftListingInfo.index;
      if (nftOwn.status === NftStatus.DELEGATE) 
        acc.push({ ...nftOwn, ...nftListingInfo.listing });
    });

    return acc;

  }, [getListingNftInfo, isLoading, nftList, receivedNfts]);

  const listedNfts = React.useMemo(() => {
    return nftsParsed.reduce((acc: NFTListing[], nft: NFTListing) => {
      if (blacklist.includes(nft.index)) {
        return acc;
      }

      if (nft.status !== NftStatus.LISTED) {
        return acc;
      }

      if (acc.some(item => nft.nftId === item.nftId && item.collection === nft.collection)) {
        return acc;
      }

      if (receivedNfts.some(item => nft.nftId === item.nftId)) {
        return acc;
      }

      return [...acc, nft];
    }, [] as NFTListing[]);
  }, [nftsParsed, receivedNfts]);

  const isListed = (nftId: string) => (
    listedNfts.some(item => item.nftId === nftId)
  );
  
  return {
    isLoading: isLoading,
    error: errorIpfs,
    allNfts: nftsParsed, //All nfts from contract list parsed
    listedNfts,
    ownNfts, //All nfts where connected user is owner
    delegatedNfts, //Only Delegated
    receivedNfts, //Received nfts Rented & Delegated
    isListed,
  };
};

/**
 * Nfts Provider Creation.
 * @param children React.PropsWithChildren
 */
export function NftsProvider({ children }: React.PropsWithChildren) {
  const value = useNftsProvider();
  
  return (
    <nftsContext.Provider value={value}>
      {children}
    </nftsContext.Provider>
  );
}
