// Internal components
import Lend from './Root';

const paths = {
  nftId: '/:nftId',
  collection: '/:collection',
};

export const routes = [
  {
    path: paths.nftId + paths.collection,
    Page: Lend,
    exact: true,
  },
];