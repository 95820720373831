// Internal components
import Root from './Form';
import RentList from './RentList';

const paths = {
  list: '/',
  rent: '/:nftId/:index/:price/:collection',
};

export const routes = [
  {
    path: paths.list,
    Page: RentList,
    exact: true,
  },
  {
    path: paths.rent,
    Page: Root,
    exact: true,
  },
];
