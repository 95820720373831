import React from 'react';

// Material
import { Box, Tabs, Tab } from '@mui/material';

// Utils
import { generateKey } from 'utils/react';

interface CustomTab<T = string> {
  label: string; value: T 
}

interface CustomTabsProps<T = string> {
  children: React.ReactNode;
  selected: T;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: string) => void;
  tabs: CustomTab<T>[];
}

const CustomTabs: React.FC<CustomTabsProps> = ({
  children,
  selected,
  onChange,
  tabs,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={selected}
        onChange={onChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
        sx={{ borderBottom: '1px solid' }}
      >
        {tabs.map(({ value, label }) => (
          <Tab key={generateKey('custom-tab')} value={value} label={label} />
        ))}
      </Tabs>

      {children}
    </Box>
  );

};

export default CustomTabs;
