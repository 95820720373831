import React from 'react';

// Shared
import { Flex } from 'components/Shared';

// Bussiness
import { CardWithImage as WelcomeCard } from 'components/BussinesShared';

// Images
import { NftIcon } from 'Images';

// Styles
import BaseStyles from './style';

interface WelcomeProps {
  onClick: () => void;
}

// eslint-disable-next-line max-len
const line1 = 'Renting an NFT is easy and fast. You can choose the day and test your NFT wherever you want.';
const line2 = 'Choose the nft you want to rent';
const line3 = 'Select the rent dates';
const line4 = 'Leave your collateral';
const line5 = 'Thats it! Try and use your new NFT';
const line6 = 'We will return it when your time it´s up and you send back the NFT to the original owner.';

const Welcome: React.FC<WelcomeProps> = ({
  onClick,
}) => {
  return (
    <Flex styles={BaseStyles.root}>
      <WelcomeCard
        Icon={NftIcon}
        title="Welcome!"
        description={[
          line1,
          `${line2} \n
           ${line3} \n
           ${line4} \n
           ${line5} \n `, 
          line6,
        ]}
        callToActionProps={{ text: 'Next', onClick }}
      />
    </Flex>
  );
};

export default Welcome;
