import { styled } from '@mui/material';
import { Colors } from 'styles';

// Shared Components
import { Flex } from 'components/Shared';

// Flex overrides
export const StyledRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  width: '100%',
  height: '100%',
  flexWrap: 'wrap',
});

export const StyledFlexSpaceBetween = styled(Flex)({
  justifyContent: 'space-between',
});

export const TabMenuItemsContainer = {
  display: 'flex',
  flex: 1,
  maxWidth: 500,
  justifyContent: 'space-around',
  cursor: 'pointer',
};

const getStyles = (isMobile: boolean) =>  ({
  //Containers
  root: {
    padding: '37px 32px',
    minWidth: isMobile ? '100%' : '416px',
  },
  header: {
    padding: '16px 32px 32px 32px',
  },
  closeContainer: {
    alignItems: 'flex-end',
  },
  closeIcon: {
    maxWidth: 40,
    border: 'none',
  },
  listItem: {
    padding: 0,
  },
  addressContainer: {
    padding: '16px 0px',
  },

  divider: {
    borderTop: `1px solid ${Colors.darkGray}`,
  },
});

export default getStyles;

