import React from 'react';
import { useNavigate } from 'react-router-dom';

// Shared
import { Button, Flex } from 'components/Shared';

// Bussiness
import { CardWithImage as CongratulationsCard } from 'components/BussinesShared';

// Config
import { redirect } from 'components/routes/config';

// Images
import { CongratsIcon } from 'Images';

// Hooks
import useDevice from 'hooks/useDevice';

// Styles
import BaseStyles from './style';
import { MyNFTsTabs } from 'pages/MyNfts/types';

interface CongratulationsProps {
}

const line1 = 'You\'ve just rented and woke up your first NFT.';
const line2 = 'Be free to use it wherever you want during your rental period ;)';

const Congratulations: React.FC<CongratulationsProps> = ({
}) => {
  // Hooks
  const { isMobile } = useDevice();
  const navigate = useNavigate();

  return (
    <Flex styles={BaseStyles.root}>
      <CongratulationsCard
        Icon={CongratsIcon}
        title="Congratulations!"
        description={[
          line1,
          line2,
        ]}
        CallToAction={() => 
          <Flex styles={{ gap: 8, width: '100%', justifyContent: 'flex-end' }} row={!isMobile}>
            <Button
              size={isMobile ? 'none' : 'large'}
              color="secondary"
              text="See My NFTs"
              onClick={() => navigate(redirect.myNfts({ activeTab: MyNFTsTabs.DEFAULT }))}
            />
            <Button //Provisory demo
              size={isMobile ? 'none' : 'large'}
              text="Use NFT"
              onClick={() => window.open('https://testnet.tokengated.wakeuplabs.io/', '_blank')}
            />
            {/* <Button //Provisory demo
              text="Go to Home"
              onClick={() => navigate(redirect.home())}
            /> */}
          </Flex>
        }
      />
    </Flex>
  );
};

export default Congratulations;
