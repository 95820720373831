import Router from 'components/routes/Router/Router';
import React from 'react';
import { routes } from './routes';

interface MyNftsProps {}

const MyNfts: React.FC<MyNftsProps> = () => {
  return (
    <Router routes={routes} />
  );
};

export default MyNfts;