// Layout
import layout from 'components/BussinesShared/layout';

// Pages
import Home from 'pages/Home';
import Storybook from 'pages/Storybook';
import Lend  from 'pages/Lend';
import MyNfts from 'pages/MyNfts/MyNfts';
import Rent from 'pages/Rent/Rent';
import NftDetails from 'pages/NftDetails';
import Swap from 'pages/Swap';
import Mint from 'pages/Mint/Root';
import { Collections } from 'pages/Collections';

export enum BackgroundEnum {
  gray = 'gray',
  difuminated = 'difuminated',
}

const simplePaths = {
  // Generics
  empty: '/',
  home: '/',
  activeTab: '/:activeTab',
  nftId: '/:nftId',
  index: '/:index',
  price: '/:price',
  collection: '/:collection',
  create: '/create',
  // My Nfts routes
  myNfts: '/my-nfts',
  delegate: '/delegate',
  own: '/own',
  received: '/received',
  lend: '/lend',
  rent: '/rent',
  nftDetails: '/nftDetails',
  swap: '/swap',
  mint: '/mint',
  collections: '/collections',
  // Test
  storybook: '/storybook',
  testForm: '/test-form',
  testPage: '/test-page',
  testRent: '/test-rent',
};

const redirect = {
  empty: () => simplePaths.empty,
  home: () => simplePaths.home,
  myNfts: ({ activeTab = 'own' }: CommonJSON<string>) =>`${simplePaths.myNfts}/${activeTab}`,
  nftDetails: ({
    index,
    nftId,
    collection,
  }: CommonJSON<string | number>) => `${simplePaths.nftDetails}/${nftId}/${index}/${collection}`,
  lend: ({ nftId = '0', collection }: CommonJSON<string | number>) =>`${simplePaths.lend}/${nftId}/${collection}`,
  rentList: () => simplePaths.rent,
  rent: ({
    nftId,
    index,
    price,
    collection,
  }: CommonJSON<string>) => `${simplePaths.rent}/${index}/${nftId}/${price}/${collection}`,
  create: () => simplePaths.create,
  storybook: () => simplePaths.storybook,
  testForm: () => simplePaths.storybook + simplePaths.testForm,
  testRent: () => simplePaths.storybook + simplePaths.testRent,
  testPage: () => simplePaths.testPage,
  delegate: ({ activeTab = 'own' }: CommonJSON<string>) =>`${simplePaths.delegate}/${activeTab}`,
  swap: () => simplePaths.swap,
  mint: () => simplePaths.mint,
  collections: () => simplePaths.collections,
  collectionsList: ({ collectionId }: CommonJSON<string>) => `${simplePaths.collections}/${collectionId}`,
};

const routes: Routes = {
  public: [
    /*  { TODO: Provisory, change when home implemented
      to: redirect.home(),
      path: simplePaths.home,
      Page: Home,
      Layout: layout,
      Background: BackgroundEnum.difuminated,
      exact: false,
    }, */
    {
      to: redirect.rentList(),
      path: simplePaths.home,
      label: 'Rent',
      isShownInTab: false,
      Page: Rent,
      Layout: layout,
      Background: BackgroundEnum.gray,
      exact: false,
    },  
    {
      to: redirect.rentList(),
      path: simplePaths.rent,
      label: 'Rent',
      isShownInTab: true,
      Page: Rent,
      Layout: layout,
      Background: BackgroundEnum.gray,
      exact: false,
    },    
  ],
  private: [
    {
      to: redirect.delegate({ activeTab: 'own' }),
      path: simplePaths.delegate,
      label: 'Delegate',
      isShownInTab: true,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: MyNfts,
      exact: false,
    },
    {
      to: redirect.myNfts({ activeTab: 'own' }),
      path: simplePaths.myNfts,
      label: 'My NFTs',
      isShownInTab: true,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: MyNfts,
      exact: false,
    },
    {
      to: redirect.swap(),
      path: simplePaths.swap,
      label: 'Swap',
      isShownInTab: true,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Swap,
      exact: false,
    },
    {
      to: redirect.create(),
      path: simplePaths.create,
      label: 'Create NFT',
      isShownInTab: false,
      Page: Home,
      Layout: layout,
      exact: false,
    },
    {
      to: redirect.storybook(),
      path: simplePaths.storybook,
      label: 'Storybook',
      isShownInTab: false,
      Page: Storybook,
      Layout: layout,
      exact: false,
    },
    {
      to: redirect.lend({ nftId: '1' }),
      path: simplePaths.lend,
      label: 'Lend',
      isShownInTab: false,
      Page: Lend,
      Layout: layout,
      Background: BackgroundEnum.difuminated,
      exact: false,
    },
    {
      path: `${simplePaths.nftDetails}${simplePaths.nftId}${simplePaths.index}${simplePaths.collection}`,
      Page: NftDetails,
      Layout: layout,
      Background: BackgroundEnum.difuminated,
      exact: false,
    },
    {
      path: `${simplePaths.nftDetails}${simplePaths.nftId}`,
      Page: NftDetails,
      Layout: layout,
      Background: BackgroundEnum.difuminated,
      exact: false,
    },
    {
      to: redirect.mint(),
      path: simplePaths.mint,
      label: 'Mint NFT',
      isShownInTab: true,
      Page: Mint,
      Layout: layout,
      Background: BackgroundEnum.gray,
      exact: false,
    },
    {
      to: redirect.collections(),
      path: simplePaths.collections,
      Page: Collections,
      Layout: layout,
      Background: BackgroundEnum.gray,
      exact: false,
    },
  ],  
};

// TODO: make it better with connection state
const tabRoutes = [...routes.public, ...routes.private].filter(route => route.isShownInTab);

export {
  tabRoutes,
  routes,
  redirect,
};

