import React from 'react';

// Shared
import { CustomText, Flex } from 'components/Shared';

// Bussiness
import { CardWithImage as EmptyStateCard } from 'components/BussinesShared';

// Images
import { EmptyStateNftIcon } from 'Images';

//MaterialUI
import { Box } from '@mui/system';

//Styles
import { FontSize, FontWeight } from 'styles';
import BaseStyles from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EmptyStateProps {
}

const EmptyState: React.FC<EmptyStateProps> = () => {
  return (
    <Flex styles={BaseStyles.root}>
      <CustomText fontSize={FontSize.xxxxlarge} style={BaseStyles.textStyles} fontWeight={FontWeight.extraBold}>
        Swap
      </CustomText>
      <div style={BaseStyles.divider} />
      <Box alignSelf={'center'} marginTop='32px'>
        <EmptyStateCard
          Icon={EmptyStateNftIcon}
          title="Coming Soon"
          description={['You`ll be able to swap your NFTs soon' ]}
        />
      </Box>
    </Flex>
  );
};

export default EmptyState;
