import React from 'react';
import { useNavigate } from 'react-router-dom';

// Shared
import { Button, CustomText } from 'components/Shared';

// Config
import { redirect } from 'components/routes/config';

interface NotFoundProps {
}

const NotFound: React.FC<NotFoundProps> = () => {
  const navigate = useNavigate();

  return (
    <>
      <CustomText>Sorry, this page was not found</CustomText>

      <Button text="Home" onClick={() => navigate(redirect.home())} />
    </>
  );
};

export default NotFound;
