import React from 'react';

//MateralUI
import { TypographyProps } from '@mui/system';

// Hooks
import useDevice from 'hooks/useDevice';

// Styles
import { FontFamily, FontSize, FontWeight } from 'styles/font';

export interface CustomTextProps extends TypographyProps {
  fontFamily?: FontFamily;
  fontWeight?: FontWeight | [FontWeight, FontWeight];
  fontSize?: FontSize | [FontSize, FontSize];
  style?: Styles;
  children?: React.ReactNode;
}

const CustomText: React.FC<CustomTextProps> = ({
  fontFamily = FontFamily.default,
  fontWeight = FontWeight.default,
  fontSize = FontSize.default,
  style,
  children,
}) => {
  // Hooks
  const { getStyle } = useDevice();
  
  return (
    <div
      style={{
        fontFamily,
        fontWeight: getStyle<FontWeight>(fontWeight),
        fontSize: getStyle<FontSize>(fontSize),
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default CustomText;
