// Internal components
import Swap from './SwapEmptyState';

const paths = {
  swap: '/',
};

export const routes = [
  {
    path: paths.swap,
    Page: Swap,
    exact: true,
  },
];
