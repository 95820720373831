import { BigNumber } from 'ethers';

// Enums
import { NftStatus } from 'enums/nft';

export enum ConverterTypes {
  BIG_NUMBER = 'bignumber',
  STATUS = 'status',
}

/**
 * Returns the status depending on the state of the nft
 * @param nft nft necessary to define which state has the nft
 * @returns {NftStatus}
 */
const getStatus = (nft: NFTListing) => {
  const { status } = nft;
  if (+status === 0) {
    return NftStatus.LISTED;
  }
  if (+status === 1 && nft.expirationDate && nft.expirationDate > new Date()) {
    return NftStatus.LENDED;
  }

  //Add status 1 & no expiration date state Finished
  //Status =2  cancelled

  return NftStatus.AVAILABLE;
};

// Conditional Types
type ConvertionBigNumberProps = number | string | null | undefined;
function to(data: NFTRaw, type: ConverterTypes.STATUS): NftStatus;
function to(data: ConvertionBigNumberProps, type: ConverterTypes.BIG_NUMBER): number;

// Implementation
/**
 * Use to parse the values in the nft
 * @param data 
 * @param type 
 * @returns 
 */
function to(data?: NFTRaw | ConvertionBigNumberProps, type: ConverterTypes = ConverterTypes.BIG_NUMBER) {
  if (!data) {
    return data;
  }

  if (type === ConverterTypes.BIG_NUMBER) {
    return +BigNumber.from(+data);
  }

  if (type === ConverterTypes.STATUS) {
    return getStatus(data as NFTRaw);
  }
}

export const converter = { to };

