/* eslint-disable max-len */
const collections = [
  // {
  //   name: 'Le Coq Sportif - Argentina 1986 Jersey - Goerli',
  //   description: 'This is a unique collection, of the most important jersey of the Argentinean national football team: the 1986 world cup champions.',
  //   images: [
  //     'https://gateway.pinata.cloud/ipfs/QmeXrGbY62LMVdic13xMXwCAk7p1qEp69rckk7bMZ38max',
  //     'https://gateway.pinata.cloud/ipfs/QmeXrGbY62LMVdic13xMXwCAk7p1qEp69rckk7bMZ38max',
  //     'https://gateway.pinata.cloud/ipfs/QmeXrGbY62LMVdic13xMXwCAk7p1qEp69rckk7bMZ38max',
  //     'https://gateway.pinata.cloud/ipfs/QmeXrGbY62LMVdic13xMXwCAk7p1qEp69rckk7bMZ38max',
  //   ],
  //   owner: 'DFC257',
  //   collectionId: '0xfdceb788c4165085a1ebb74f2d3ef37c78830f9d',
  // },
  {
    name: 'DelegableToken',
    description: 'Welcome to the home of DelegableToken on OpenSea. Discover the best items in this collection.',
    image: 'https://firebasestorage.googleapis.com/v0/b/rental-p2p-dapp.appspot.com/o/collection-rental%2Fimgs%2F5.png?alt=media&token=ee314900-ebd2-492a-9f32-138b6eb97574',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/rental-p2p-dapp.appspot.com/o/collection-rental%2Fimgs%2F5.png?alt=media&token=ee314900-ebd2-492a-9f32-138b6eb97574',
      'https://firebasestorage.googleapis.com/v0/b/rental-p2p-dapp.appspot.com/o/collection-rental%2Fimgs%2F1.png?alt=media&token=ed0f3bac-3c9f-49f8-802e-6cfc2738f397',
      'https://firebasestorage.googleapis.com/v0/b/rental-p2p-dapp.appspot.com/o/collection-rental%2Fimgs%2F3.png?alt=media&token=4f412eab-e0c0-478d-ae68-3314368f7940',
      'https://firebasestorage.googleapis.com/v0/b/rental-p2p-dapp.appspot.com/o/collection-rental%2Fimgs%2F2.png?alt=media&token=efaf9b6a-7da0-4188-a419-20f74380532d',
    ],
    owner: 'DFC257',
    collectionId: '0xfeff513c2c358bb01f650ce889c05999563cfb4b',
  },
  // {
  //   name: 'SOG',
  //   description: 'Owned by #D46531',
  //   owner: '#D46531',
  //   images: [
  //     'https://gateway.pinata.cloud/ipfs/QmU7aDQXssLCaHnpovpXwf32z4iaN1q67Jwd3oKLpF5SZV/9.png',
  //     'https://gateway.pinata.cloud/ipfs/QmU7aDQXssLCaHnpovpXwf32z4iaN1q67Jwd3oKLpF5SZV/9.png',
  //     'https://gateway.pinata.cloud/ipfs/QmU7aDQXssLCaHnpovpXwf32z4iaN1q67Jwd3oKLpF5SZV/9.png',
  //     'https://gateway.pinata.cloud/ipfs/QmU7aDQXssLCaHnpovpXwf32z4iaN1q67Jwd3oKLpF5SZV/9.png',
  //   ],
  //   collectionId: '0x49fdc46207d05866E57B712556d94c135BBa6998',
  // },
];

export default collections;
