// Apollo
import { ApolloClient, InMemoryCache } from '@apollo/client';

// Config
import { envKeys } from './envKeys';

export const client = new ApolloClient({
  uri: envKeys.apolloClient.uri,
  cache: new InMemoryCache(),
});
  