import React from 'react';
import { Avatar } from '@mui/material';

// Hooks
import useStyles from 'hooks/useStyles';

// Styles
import getStyles from './style';

interface CustomAvatarProps {
  size?: 'big';
  children?: React.ReactNode;
}

const CustomAvatar: React.FC<CustomAvatarProps> = ({
  size,
  children,
}) => {
  const { BaseStyles } = useStyles({ getStyles: () => getStyles({ size }) });

  return (
    <Avatar style={BaseStyles.root}>
      {children}
    </Avatar>
  );
};

export default CustomAvatar;
