//Styles
import '@rainbow-me/rainbowkit/styles.css';

//Crypto
import { getDefaultWallets } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

//Env
import { envKeys } from './envKeys';

const { chains, provider } = configureChains(
  [chain.polygon, chain.polygonMumbai],
  [
    alchemyProvider({ alchemyId: envKeys.alchemy.id  }),
    publicProvider(),
  ],
);
  
const { connectors } = getDefaultWallets({
  appName: 'WakeUp',
  chains,
});
  
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export { wagmiClient, chains };