import { minHeightTobBar } from 'styles/constants';

const BaseStyles = {
  root: {
    width: '100%',
    minHeight: `calc(100vh - ${minHeightTobBar})`,
  },
};

export default BaseStyles;
