import React from 'react';

// Shared
import { CustomText, Flex } from 'components/Shared';

// Styles
import { FontSize, FontWeight } from 'styles';
import BaseStyles from './style';

export enum CardDetailsLineVariant {
  TITLE = 'title',
  LINE = 'line',
  TOTAL = 'total',
  CUSTOM = 'custom',
}

interface CardDetailsLineProps {
  children?: React.ReactNode,
  variant: CardDetailsLineVariant;
  withDivider?: boolean,
  withPadding?: boolean,
  // title
  title?: string;
  Icon?: Icon;
  // line
  label?: string;
  value?: string | number; 
  boldValue?: string | number; 
}

const CardDetailsLine: React.FC<CardDetailsLineProps> = ({
  title,
  Icon,
  label,
  value,
  boldValue,
  withDivider,
  withPadding,
  variant = CardDetailsLineVariant.LINE,
  children,
}) => {
  // Dynamic styles
  const styles = withPadding ? { paddingTop: 24 } : {};

  // Variants
  if (variant === CardDetailsLineVariant.TOTAL) {
    return (
      <Flex styles={{ ...BaseStyles.rootLine, ...styles }} row>
        <CustomText
          fontWeight={FontWeight.extraBold}
          fontSize={FontSize.large}
        >
          {label}
        </CustomText>
        <Flex row styles={{ gap: 4 }}>
          {boldValue && (
            <CustomText
              fontWeight={FontWeight.extraBold}
              fontSize={FontSize.large}
            >
              {boldValue}
            </CustomText>
          )}

          {value && (
            <CustomText
              fontWeight={FontWeight.medium}
              fontSize={FontSize.regular}
            >
              {value}
            </CustomText>
          )}
        </Flex>
      </Flex> 
    );
  }

  if (variant === CardDetailsLineVariant.TITLE) {
    return (
      <Flex styles={{ ...BaseStyles.rootTitle, ...styles }} row>
        {Icon && <Icon />}
        <CustomText
          fontWeight={FontWeight.medium}
          fontSize={FontSize.xlarge}
        >
          {title}
        </CustomText>
      </Flex> 
    );
  }
  
  if (variant === CardDetailsLineVariant.LINE)
    return (
      <>
        <Flex styles={{ ...BaseStyles.rootLine, ...styles }} row>
          <CustomText
            fontWeight={FontWeight.regular}
            fontSize={FontSize.small}
          >
            {label}
          </CustomText>

          <Flex row styles={{ gap: 4 }}>
            {boldValue && (
              <CustomText
                fontWeight={FontWeight.medium}
                fontSize={FontSize.regular}
              >
                {boldValue}
              </CustomText>
            )}

            {value && (
              <CustomText
                fontWeight={FontWeight.regular}
                fontSize={FontSize.regular}
              >
                {value}
              </CustomText>
            )}
          </Flex>
        </Flex> 

        {/* Replace by custom divider when merge */}
        {withDivider && (
          <div style={{ borderBottom: '1px solid #CACACA' }} />
        )}
      </>
    );

  if (variant !== CardDetailsLineVariant.CUSTOM) {
    return (
      <>
        <Flex styles={{ ...BaseStyles.rootLine, ...styles }} row>
          {/* TITLE PROPS */}
          {Icon && <Icon />}
          <CustomText
            fontWeight={FontWeight.medium}
            fontSize={FontSize.xlarge}
          >
            {title}
          </CustomText>

          {/* LINE PROPS */}
          <CustomText
            fontWeight={FontWeight.regular}
            fontSize={FontSize.small}
          >
            {label}
          </CustomText>

          <Flex row styles={{ gap: 4 }}>
            {boldValue && (
              <CustomText
                fontWeight={FontWeight.medium}
                fontSize={FontSize.regular}
              >
                {boldValue}
              </CustomText>
            )}

            {value && (
              <CustomText
                fontWeight={FontWeight.regular}
                fontSize={FontSize.regular}
              >
                {value}
              </CustomText>
            )}
          </Flex>
        </Flex> 

        {/* Replace by custom divider when merge */}
        {withDivider && (
          <div style={{ borderBottom: '1px solid #CACACA' }} />
        )}
      </>
    );
  }

  // CUSTOM
  return (
    <Flex>
      {children}
    </Flex> 
  ); 
};

export default CardDetailsLine;
