import { Colors } from 'styles';

const BaseStyles = {
  root: { 
    alignItems: 'flex-start', 
  },
  textStyles: {
    paddingTop: '32px',
    alignItems: 'flex-start',
  },
  divider: {
    borderBottom: `1px solid ${Colors.black}`, 
    width: '100%', 
  },
};

export default BaseStyles;
