enum HTTPMethod {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

interface HttpRequest {
  headers?: CommonJSON;
  body?: string;
  method: HTTPMethod;
}

const buildRequest = (verb: HTTPMethod, data?: CommonJSON, headers = null) => {
  const request: HttpRequest = {
    method: verb,
  };
  if (headers !== null) {
    request.headers = headers;
  }
  if (verb === HTTPMethod.POST) {
    request.body = JSON.stringify(data);
  } else if (verb === HTTPMethod.PUT) {
    request.body = JSON.stringify(data);
  } else if (verb === HTTPMethod.PATCH) {
    request.body = JSON.stringify(data);
  }

  return request;
};

const createParams = (data: CommonJSON) => {
  const params = Object.entries(data).reduce((acc, [key, value], index) => {
    const result = `${key}=${value}`;
    if (index === 0) {
      return `?${result}`;
    }

    return `${acc}&${result}`;
  }, '');

  return params;
};

export const sendRequest = async (
  url: string,
  resources?: string,
  params?: CommonJSON,
  method: HTTPMethod = HTTPMethod.GET,
): Promise<CommonJSON | undefined> => {
  const request = buildRequest(method);

  const paramsParsed = createParams(params || {});
  const reqUrl = paramsParsed ? `${url}${paramsParsed}` : url;

  try {
    const response = await fetch(reqUrl, request);

    return response.json() as CommonJSON;
  } catch (e) {
    console.error('error', e);
  }
};
