const getStyles: StylesFn = isMobile => ({
  //Containers
  root: {
    gap: 24,
    width: isMobile ? '100%' : undefined,
  },
  timeLeftCard: {
    textAlign: isMobile ? 'center' : undefined,
  },
  buttonContainer: {
    width: '100%',
    gap: 16,
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  button: {
    width: isMobile ? '100%' : '175px',
  },
});
    
export default getStyles;
    