import React from 'react';
import {
  differenceInDays,
  format,
  formatDistance,
} from 'date-fns';

// Business
import { CardDetails } from 'components/BussinesShared';

// Shared
import { CustomText, Flex } from 'components/Shared';

// Images
import { CalendarIcon, CryptoIcon } from 'Images';

// Hooks
import useStyles from 'hooks/useStyles';

// Styles
import getStyles from './style';
import { FontSize, FontWeight } from 'styles';
import { getPricePerDayFromPerSec } from 'utils/priceUtils';

interface LendedProps {
  nft: NFT;
}

const Lended: React.FC<LendedProps> = ({
  nft,
}) => {
  // Hooks
  const { BaseStyles } = useStyles({ getStyles });
  // Dates parsed
  const from = nft.rentStartedTimestamp!;
  const to = nft.expirationDate!;
  const daysToRent = differenceInDays(to, from);
  const timeLeft = formatDistance(to, new Date());

  // Variables
  const lendSections = [
    {
      title: 'Rent Time',
      Icon: CalendarIcon,
      lines: [
        {
          label: 'From',
          value: format(from, 'PPpp'),
          withDivider: true,
        },
        {
          label: 'To',
          value: format(to, 'PPpp'),
          withDivider: true,
        },
        {
          label: 'Total rent time',
          value: `${daysToRent.toString()} days`,
          withDivider: true,
        },
      ],
    },
    {
      title: 'Rent Price',
      Icon: CryptoIcon,
      lines: [
        {
          label: 'Daily price',
          boldValue: `${getPricePerDayFromPerSec(nft.rentPriceSecond)} ETH`,
          withDivider: true,
        },
        /* {
          label: 'Collateral',
          value: daysToRent * nft.rentPriceSecond * 60 * 60 * 24,
          withDivider: true,
        }, */
      ],
    },
  ];

  return (
    <Flex styles={BaseStyles.root}>
      <CardDetails
        sections={lendSections}
      />

      <CardDetails styles={BaseStyles.timeLeftCard} color='secondary'>
        <CustomText
          fontWeight={FontWeight.default}
          fontSize={FontSize.regular}
        >
          {`${timeLeft} left to finalize the rent`}
        </CustomText>
      </CardDetails>
    </Flex>
  );
};

export default Lended;
