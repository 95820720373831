const BaseStyles = {
  customCardStyles: { 
    maxWidth: 420, 
    height: '100%', 
    padding: '32px', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'space-between',
    gap: 16, 
  },
  textFieldPrice: { 
    sx: { width: '165px' }, 
  },
  priceContainer: { 
    flexDirection: 'row',
    gap: 8,
  },
  delegationStyles: {
    alignItems: 'flex-start',
    gap: 16, 
  },
};

export default BaseStyles;
