import { addDays } from 'date-fns';

const nftFake = {
  name: 'NFT Name',
  collection: 'Collection',
};

enum NftStatus {
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  LENDED = 'lended',
  LISTED = 'listed',
  DELEGATE = 'delegate',
}

const nftsFake: NFT[] = [
  {
    description: 'Description of your NFT', 
    image: 'ipfs://QmTnFoMd1FewuGdcSUoBETo4Gk9bNJ31qNjQ4WKx3uXK5v/1.png', 
    name: 'First NFT',
    status: NftStatus.AVAILABLE,
    rentPriceSecond: 0.06,
    nftId: '1',
    rentStartedTimestamp: addDays(new Date(), -2),
    rentCost: 0.08,
    maximumSecondsToRent: 3 * 24 * 60 * 60, // 3 days
    collection: '',
    expirationDate: new Date(),
    owner: '',
    renter: '',
    index: 1,
    tokenId: 1,
  },
  {
    description: 'Description of your NFT', 
    image: 'ipfs://QmTnFoMd1FewuGdcSUoBETo4Gk9bNJ31qNjQ4WKx3uXK5v/1.png', 
    name: 'First NFT',
    status: NftStatus.LENDED,
    rentPriceSecond: 0.06,
    nftId: '2',
    rentStartedTimestamp: addDays(new Date(), -2),
    rentCost: 0.08,
    maximumSecondsToRent: 3 * 24 * 60 * 60, // 3 days
    collection: '',
    expirationDate: new Date(),
    owner: '',
    renter: '',
    index: 1,
    tokenId: 1,
  }, {
    description: 'Description of your NFT', 
    image: 'ipfs://QmTnFoMd1FewuGdcSUoBETo4Gk9bNJ31qNjQ4WKx3uXK5v/1.png', 
    name: 'First NFT',
    status: NftStatus.LISTED,
    rentPriceSecond: 0.06,
    nftId: '3',
    rentStartedTimestamp: addDays(new Date(), -2),
    rentCost: 0.08,
    maximumSecondsToRent: 3 * 24 * 60 * 60, // 3 days
    collection: '',
    expirationDate: new Date(),
    owner: '',
    renter: '',
    index: 1,
    tokenId: 1,
  },
  {
    description: 'Description of your NFT', 
    image: 'ipfs://QmTnFoMd1FewuGdcSUoBETo4Gk9bNJ31qNjQ4WKx3uXK5v/1.png', 
    name: 'First NFT',
    status: NftStatus.AVAILABLE,
    rentPriceSecond: 0.06,
    nftId: '4',
    rentStartedTimestamp: addDays(new Date(), -2),
    rentCost: 0.08,
    maximumSecondsToRent: 3 * 24 * 60 * 60, // 3 days
    collection: '',
    expirationDate: new Date(),
    owner: '',
    renter: '',
    index: 1,
    tokenId: 1,
  },
  {
    description: 'Description of your NFT', 
    image: 'ipfs://QmTnFoMd1FewuGdcSUoBETo4Gk9bNJ31qNjQ4WKx3uXK5v/1.png', 
    name: 'First NFT',
    status: NftStatus.LENDED,
    rentPriceSecond: 0.06,
    nftId: '5',
    rentStartedTimestamp: addDays(new Date(), -2),
    rentCost: 0.08,
    maximumSecondsToRent: 3 * 24 * 60 * 60, // 3 days
    collection: '',
    expirationDate: new Date(),
    owner: '',
    renter: '',
    index: 1,
    tokenId: 1,
  },
  {
    description: 'Description of your NFT', 
    image: 'ipfs://QmTnFoMd1FewuGdcSUoBETo4Gk9bNJ31qNjQ4WKx3uXK5v/1.png', 
    name: 'First NFT',
    status: NftStatus.DELEGATE,
    rentPriceSecond: 0.06,
    nftId: '21',
    rentStartedTimestamp: addDays(new Date(), -2),
    rentCost: 0.08,
    maximumSecondsToRent: 3 * 24 * 60 * 60, // 3 days
    collection: '',
    expirationDate: new Date(),
    owner: '',
    renter: '',
    index: 1,
    tokenId: 1,
  },
];

/**
 * mock the get NFT by id query. It searches in the mock list
 * @param id string
 * @returns {NFT | undefined}
 */
const getNftById = (id?: string) => {
  if (!id) return undefined;

  const nft = nftsFake.find(item => item.nftId === id);
  
  return nft;
};

const getNftRandom = () => {
  const index = Math.floor(Math.random() * nftsFake.length);
  const nft = nftsFake[index];
  
  return nft;
};

export {
  nftFake,
  nftsFake,
  getNftById,
  getNftRandom,
};