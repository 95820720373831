import { ReactNode } from 'react';

//Shared
import { Flex, CustomText, Loading } from 'components/Shared';

//Styles
import {
  boxButtonStyles,
  boxMobileButtonStyles,
  buttonsFlexStyles,
  flexStyles,
  flexStylesMobile,
  StyledImg,
} from './styles';
import { FontSize, FontWeight } from 'styles/font';
import { Box } from '@mui/system';

//Hooks
import useDevice from 'hooks/useDevice';
import { useGetNftDataById } from 'api/schema/nft';
import { useParams } from 'react-router-dom';
import useStyles from 'hooks/useStyles';

//Utils
import { parseIpfs } from 'api/utils/tokenUri';

// Styles
import getStyles from 'styles/globalStyles';

interface NftCardProps {
  id: string,
  title?: string,
  subtitle?: string,
  label?: string,
  labelTextColor?: string,
  children?:ReactNode
}

const NftCardSimple : React.FC<NftCardProps> = ({
  id, 
  children, 
}) =>{
  // Hooks
  const { collection } = useParams();
  const { isMobile }  = useDevice();
  const { loading: loading, data: nft } = useGetNftDataById(id!, collection);

  // Styles
  const { BaseStyles: GlobalStyles } = useStyles({ getStyles });
  
  return (
    <Flex styles={isMobile ? flexStylesMobile : flexStyles}>
      {(loading || !nft?.name) ? 
        <Loading />
        :
        <>
          <StyledImg
            src={parseIpfs(nft?.image)}
            width={isMobile ? 106 : '100%'}
            height={isMobile ? 106 : '100%'}
            alt='nftImage'
          />
          <Box sx={isMobile ? boxMobileButtonStyles : boxButtonStyles}>
            <CustomText fontSize={FontSize.large} fontWeight={FontWeight.extraBold}>
              {nft?.name} {id}
            </CustomText>
            <CustomText style={GlobalStyles.ellipsis}>
              {nft?.description}
            </CustomText>
            <Flex styles={buttonsFlexStyles}>
              {children}
            </Flex>
          </Box>
        </>}
    </Flex>
  );
};

export default NftCardSimple;
