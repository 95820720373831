export const getNftIdMod = (id: number | string): number => (+id % 6) + 1;

export const getAddressHidden = (address = ''): string => {
  if (address.length < 14) {
    return address;
  }
  const firstPart = address.slice(0, 6);
  const lastPart = address.slice(address.length - 7, address.length);

  return `${firstPart}...${lastPart}`;
};
