//Styles
import { Colors } from 'styles';

export const filterBoxStyles = { 
  display: 'flex',
  justifyContent: 'flex-end',
  width: '96%',
  marginBottom: '3%',
  marginTop: '1%', 
};

export const boxStyles = { 
  width: '100%', 
  display: 'grid', 
  gridTemplateColumns: 'repeat(auto-fill, 320px)', 
  gridTemplateRows: 'repeat(auto-fill, 430px)', 
  gap: '40px', 
  justifyContent: 'center', 
  marginTop: 2,  
};

export const boxMobileStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 2,
  gap: '16px',
  width: '100%',
};

export const iconButtonStyles = { 
  width: 48,
  border: `1px solid ${Colors.violetMain}`,
  borderRadius: '12px',
};