import React from 'react';
import { useParams } from 'react-router-dom';

// Bussiness Components
import { CardDetails } from 'components/BussinesShared';

// Shared
import { Button, Flex } from 'components/Shared';

// Hooks
import useDevice from 'hooks/useDevice';
import useConfirm from './useConfirm';

// Styles
import BaseStyles from './style';

//Form
import { Control, useWatch } from 'react-hook-form';

//Utils
import { delegateSections } from './utils';

interface SummaryProps {
  onClick: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  id: string;
}

const SummaryDelegation: React.FC<SummaryProps> = ({
  onClick,
  control,
  id,
}) => {
  const { collection } = useParams();
  const { isMobile } = useDevice();
  const [timeType, time = 0, wallet] = useWatch({ control, name: ['timeType', 'time', 'wallet'] });

  const { loading, onConfirm } = useConfirm({
    id,
    wallet: wallet as string,
    time: time as number,
    timeType: timeType as string,
    collection,
    onClick,
  }, true);
  
  return (

    <Flex styles={BaseStyles.container}>
      <CardDetails sections={delegateSections({ address: wallet, time, timeType })} />

      {!isMobile && (
        <Flex styles={BaseStyles.buttonContainer}>
          <Button
            variant='contained'
            loading={loading}
            onClick={onConfirm}
            text="Confirm"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default SummaryDelegation;
