import React from 'react';

// Shared
import { CustomText, Flex } from 'components/Shared';

// Bussiness
import { CardWithImage as EmptyStateCard } from 'components/BussinesShared';

// Images
import { EmptyStateNftIcon } from 'Images';

// Styles
import BaseStyles from './style';
import { useNavigate } from 'react-router-dom';
import { redirect } from 'components/routes/config';

// Material
import { Box } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EmptyStateProps {
}

const EmptyState: React.FC<EmptyStateProps> = () => {
  const navigate = useNavigate();

  return (
    <Flex styles={BaseStyles.root}>
      <EmptyStateCard
        Icon={EmptyStateNftIcon}
        title="You don’t have NFTs"
        FooterComponent={()=>
          <CustomText >
            You can get your first NFT for free in the  <br/>
            <Box display='flex'>
              <Box onClick={()=>navigate(redirect.mint())}>
                <CustomText style={{ cursor: 'pointer', textDecoration: 'underline' }} >
                  Mint NFT
                </CustomText>
              </Box>
              &nbsp; section
            </Box>
          </CustomText>
        }
        
      />
    </Flex>
  );
};

export default EmptyState;
