import { useContractRead } from 'wagmi';
import { UseContractReadConfig } from 'wagmi/dist/declarations/src/hooks/contracts/useContractRead';

// Config
import { EndpointConfig, endpoints } from 'api/schema/types';

// Types
type Options<T> = UseContractReadConfig & { args?: T };

const useCryptoRead = <Response = CommonJSON, Variables = CommonJSON | CommonJSON[]>(
  url: EndpointConfig,
  options?: Options<Variables>,
  customAddress?: string,
) => {
  // Config
  const { contractConfig, method } = endpoints[url];

  // Hooks
  const { data, isLoading, ...restResponse } = useContractRead(
    customAddress ? { ...contractConfig, addressOrName: customAddress } : contractConfig,
    method,
    options);

  return {
    data: data as unknown as Response,
    isLoading,
    ...restResponse,
  };
};

export default useCryptoRead;