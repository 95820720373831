import React from 'react';
import { 
  differenceInDays,
  format,
} from 'date-fns';

// Business
import { CardDetails } from 'components/BussinesShared';

// Shared
import { CustomText, Flex } from 'components/Shared';

// Images
import { CalendarIcon } from 'Images';

// Hooks
import useStyles from 'hooks/useStyles';

// Styles
import { FontSize, FontWeight } from 'styles';
import getStyles from './style';

interface LendedProps {
  nft: NFT;
}

const Lended: React.FC<LendedProps> = ({
  nft,
}) => {
  // Hooks
  const { BaseStyles } = useStyles({ getStyles });
  // Dates parsed
  const to = nft.expirationDate || new Date();
  const timeLeft = differenceInDays(to, new Date());
  // Memos
  const delegateSections = [
    {
      title: 'Delegate Time',
      Icon: CalendarIcon,
      lines: [
        /* {
          label: 'From',
          value: format(from, 'PPpp'),
          withDivider: true,
        }, */
        {
          label: 'To',
          value: format(to, 'PPpp'),
          withDivider: true,
        }, /* ,
        {
          label: 'Total rent time',
          value: `${daysToRent.toString()} days`,
          withDivider: true,
        }, */
      ],
    },
  ];

  return (
    <Flex styles={BaseStyles.root}>
      <CardDetails
        sections={delegateSections}
      />
      <CardDetails styles={BaseStyles.timeLeftCard} color='secondary'>
        <CustomText
          fontWeight={FontWeight.regular}
          fontSize={FontSize.regular}
        >
          {`${timeLeft} left to finalize the delegation`}
        </CustomText>
      </CardDetails>
    </Flex>
  );
};

export default Lended;
