import React from 'react';

// Components
import { CustomCard } from 'components/Shared';
import CardDetailsLine, { CardDetailsLineVariant } from '../CardDetailsLine/CardDetailsLine';

// Hooks
import useStyles from 'hooks/useStyles';

// Utils
import { generateKey } from 'utils/react';

// Styles
import { Colors } from 'styles';
import getStyles from './style';

interface CardDetailsSectionLine {
  label?: string;
  value?: string | number;
  boldValue?: string | number;
  withDivider?: boolean;
}

interface CardDetailsSection {
  title?: string;
  Icon?: Icon;
  lines?: CardDetailsSectionLine[];
}

export interface CardLendDetailsProps {
  sections?: CardDetailsSection[];
  total?: CardDetailsSectionLine;
  color?: 'primary' | 'secondary';
  children?: React.ReactNode;
  styles?: Styles
}

const CardLendDetails: React.FC<CardLendDetailsProps> = ({
  sections = [],
  total,
  color = 'primary',
  children,
  styles = {},
}) => {
  const dynamicStyles = {
    background: color === 'secondary' ? Colors.violetExtraLight : Colors.white,
  };

  const { BaseStyles } = useStyles({ getStyles });
  
  return (
    <CustomCard style={{ ...BaseStyles.root, ...dynamicStyles, ...styles }} size='medium'>
      {sections.map(({ title, Icon, lines }, index) => (
        <React.Fragment key={generateKey('CardDetails-')}>
          {title && (
            <CardDetailsLine
              Icon={Icon}
              variant={CardDetailsLineVariant.TITLE}
              title={title}
              withPadding={index !== 0}
            />
          )}

          {/* Lines */}
          {(lines || []).map(lineProps => (
            <React.Fragment key={generateKey('CardDetails-')}>
              <CardDetailsLine
                variant={CardDetailsLineVariant.LINE}
                {...lineProps}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}

      {total && (
        <CardDetailsLine
          variant={CardDetailsLineVariant.TOTAL}
          withPadding
          {...total}
        />)}

      {children}
    </CustomCard>
  );
};

export default CardLendDetails;
