enum Colors {
  primary = '#8158FC',

  white = '#fff',
  green = '#94F464',
  redLigt = '#FF9494',
  blackLight = '#333333',
  blackTitle = '#1C1E2D',
  orange = '#FFA500',
  lightBlue = '#ADD8E6',
  lightYellow = '#FFF04D',
  // Violet
  violetMain = '#8158FC',
  violetSecondary = '#5626EE',
  violetLigt = '#BAA5FC',
  violetExtraLight = '#E4DBFF',

  // gray
  extraExtraLightgray = '#C4C4C4',
  extraLightgray = '#E5E5E5',
  lightGray = '#EEEEEE',
  darkGray = '#CACACA',
  red = '#FF3C3C',
  black = '#000000',
  greenLight = '#94F464',
  greenBright = '#67F421',
}

export {
  Colors,
};
