import React from 'react';

// Shared Components
import {
  CustomCard,
  CustomText,
  Flex,
} from 'components/Shared';

// Types
import Button, { CustomButtonProps } from 'components/Shared/Button';

// Utils
import { generateKey } from 'utils/react';

// Hooks
import useDevice from 'hooks/useDevice';

// Styles
import { FontSize, FontWeight } from 'styles';
import getStyles from './style';

interface CardWithImageProps {
  Icon?: Icon;
  title?: string;
  description?: string[];
  callToActionProps?: CustomButtonProps;
  CallToAction?: React.FC<CustomButtonProps>;
  variant?: 'vertical' | 'horizontal';
  iconSize?: 'small' | 'big';
  FooterComponent?: React.FC;
}

const CardWithImage: React.FC<CardWithImageProps> = ({
  Icon,
  title,
  description = [],
  callToActionProps,
  CallToAction,
  variant = 'horizontal',
  iconSize = 'big',
  FooterComponent,
}) => {
  const { isMobile, getStyle } = useDevice();
  
  const isVertical = isMobile || variant === 'vertical';
  
  const BaseStyles = getStyles(isVertical);
  
  return (
    <Flex>
      <Flex>
        <Flex styles={BaseStyles.root}>
          <CustomCard
            style={BaseStyles.cardContainer}
            size={isVertical ? 'medium' : 'big'}
          >
            {Icon && (
              <div style={{
                ...BaseStyles.section,
                ...BaseStyles.sectionIcon,
                padding: iconSize === 'big' ? 0 : '60px 0px',
              }}
              >
                <Icon style={BaseStyles.icon} />
              </div>
            )}

            <div style={{ ...BaseStyles.section, ...BaseStyles.sectionDescription }}>
              {title && (
                <CustomText
                  fontSize={getStyle<FontSize>([FontSize.large, FontSize.xxxlarge], isVertical)}
                  fontWeight={getStyle<FontWeight>([FontWeight.medium, FontWeight.bold], isVertical)}
                  style={BaseStyles.title}
                >
                  {title}
                </CustomText>
              )}

              {description.map(line => (
                <CustomText
                  key={generateKey()}
                  style={BaseStyles.description}
                >
                  {line}
                </CustomText>
              ))}
              {FooterComponent && <FooterComponent/>}
            </div>
          </CustomCard>

          {callToActionProps && (
            <Button
              style={{ ...BaseStyles.button, ...callToActionProps.style }}
              size="large"
              {...callToActionProps}
            />
          )}
          {CallToAction && <CallToAction />}
        </Flex>
      </Flex>
    </Flex>

  );
};

export default CardWithImage;
