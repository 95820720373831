import { format } from 'date-fns';
import { CalendarIcon, CryptoIcon } from 'Images';

export const rentSections = (from:Date, to:Date, rentDays:number, timeType:string, priceIn24hs:number) =>[{
  title: 'Rent time',
  Icon: CalendarIcon,
  lines: [
    {
      label: 'From',
      value: format(from, 'PP'),
      withDivider: true,
    },
    {
      label: 'To',
      value: format(to, 'PP'),
      withDivider: true,
    },
    {
      label: 'Total rent time',
      value: `${rentDays} ${timeType}`,
      withDivider: true,
    },
  ],
},
{
  title: 'Rent price',
  Icon: CryptoIcon,
  lines: [
    {
      label: 'Daily price',
      boldValue: `${priceIn24hs.toFixed(6)} ETH`,
      withDivider: true,
    },
  ],
}];