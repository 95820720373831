import React from 'react';
import { addSeconds, differenceInDays } from 'date-fns';

// Business
import { CardDetails } from 'components/BussinesShared';

// Shared
import { Button, Flex } from 'components/Shared';

// Images
import { CalendarIcon, CryptoIcon } from 'Images';

// Hooks
import useStyles from 'hooks/useStyles';

// Styles
import getStyles from './style';

//Utils
import { getPricePerDayFromPerSec } from 'utils/priceUtils';

//Crypto
import useCryptoWrite from 'api/hooks/useCryptoWrite';
import { useAccount } from 'wagmi';
import { Alert } from '@mui/material';

interface ListedProps {
  nft: NFT;
}

const Listed: React.FC<ListedProps> = ({
  nft,
}) => {
  // Hooks
  const { BaseStyles } = useStyles({ getStyles });
  // Dates parsed
  const from = new Date();
  const rentTime = addSeconds(from, nft.maximumSecondsToRent);
  const daysToRent = differenceInDays(rentTime, from);
  const { data } = useAccount();
  const { write: cancelLend, status } = useCryptoWrite('cancelLend', { args: [nft.index] });

  // Variables
  const lendSections = [
    {
      title: 'Time Configuration',
      Icon: CalendarIcon,
      lines: [
        {
          label: 'Rent Time',
          boldValue: `${daysToRent} Days`,
          withDivider: true,
        },
        /* {
          label: 'Min Period',
          value: '-',
          withDivider: true,
        },
        {
          label: 'Max Period',
          value: `${daysToRent.toString()} days`,
          withDivider: true,
        }, */
      ],
    },
    {
      title: 'Price configuration',
      Icon: CryptoIcon,
      lines: [
        {
          label: 'Daily price',
          boldValue: `${getPricePerDayFromPerSec(nft.rentPriceSecond)} ETH`,
          withDivider: true,
        },
        /* {
          label: 'Collateral',
          value: daysToRent * nft.rentPriceSecond * 60 * 60 * 24,
          withDivider: true,
        }, */
      ],
    },
  ];
  
  return (
    <Flex styles={BaseStyles.root}>
      <CardDetails
        sections={lendSections}
      />
      {nft.owner === data?.address &&  (
        <Flex styles={BaseStyles.buttonContainer} row>
          {status === 'success' && <Alert severity="success" >Successfully unlisted</Alert>}
          <Button
            style={BaseStyles.button}
            color="secondary"
            text="Unlist"
            variant='outlined'
            onClick={() => cancelLend()}
            disabled={status === 'success'}
          />
        </Flex>)}

    </Flex>
  );
};

export default Listed;
