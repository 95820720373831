import React from 'react';

// Components Shared
import { Button, Flex } from 'components/Shared';

// Images
import { useNftList } from 'contexts/nfts';
import { useRent } from 'api/schema/nft';
import { converter } from 'utils/converter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TestRentProps {
}

const TestRent: React.FC<TestRentProps> = ({}) => {
  const { listedNfts } = useNftList();
  const [rented, setRented] = React.useState<string[]>([]);
  const [notRented, setNotRented] = React.useState<string[]>([]);
  const [show, setShowResults] = React.useState<boolean>(true);
  const [index, setIndex] = React.useState<number>(0);

  const nft = listedNfts[index];
  const price = converter.to((+nft?.rentPriceSecond || 0) * 60);
  const secondsToRent = 60;

  const { writeRent, error } = useRent({
    index: (nft?.index || 0)!.toString(),
    rentCost: price!.toString(),
    secondsToRent,
    onComplete: () => {},
  });

  const ids = listedNfts.map(item => item.nftId);

  React.useEffect(() => {
    const asyncFn = async () => {
      if (listedNfts && index < listedNfts.length) {
        try {
          writeRent();

          setIndex(prev => prev + 1);
          setRented(prev => [...prev, nft.nftId]);
        } catch (e: any) {
          console.log('error', e.reason);
          setNotRented(prev => [...prev, e]);
        }
      }
    };

    asyncFn();
  }, [index]);

  console.log({ index });

  React.useEffect(() => {
    console.log('error', error);
    if (nft && error) {
      setNotRented(prev => [...prev, nft.nftId]);
    } 
  }, [nft]);

  return (
    <Flex styles={{ padding: 40, gap: 50 }}>
      <Button onClick={() => setShowResults(prev => !prev)}>
        Test nfts
      </Button>
      {show && (<>
        <h1>Listed</h1>
        {listedNfts.map(item => (` ${item.nftId}`))}
      </>)}
      {show && (<>
        <h1>Success</h1>
        {rented.map(item => (` ${item}`))}
      </>)}
      {show && (<>
        <h1>Failed</h1>
        {notRented.map(item => (` ${item}`))}
      </>)}
    </Flex>
  );
};

export default TestRent;
