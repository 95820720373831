import React from 'react';

// Context
import { nftsContext } from './Nfts';

/**
 * Hook for Context Functions.
 */
export const useNftList = () => {
  return React.useContext(nftsContext);
};

