import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { SectionHeader, CollectionCard } from 'components/BussinesShared';
import { Flex } from 'components/Shared';

// Config
import { redirect } from 'components/routes/config';

// Api
import collections from 'api/schema/collections/mocks';

// Hooks
import useStyles from 'hooks/useStyles';

// Utils
import { generateKey } from 'utils/react';

// Styles
import getStyles from './styles';

interface CollectionsProps {
}

const Collections: React.FC<CollectionsProps> = () => {
  const navigate = useNavigate();
  const { BaseStyles } = useStyles({ getStyles });

  return (
    <SectionHeader title='Collections' variant="secondary">
      <Flex styles={BaseStyles.root} row>
        {collections.map(collection => (
          <CollectionCard
            key={generateKey('collection-')}
            title={collection.name}
            subtitle={collection.description}
            images={collection.images}
            disableHover
            variant="vertical"
            onClickCard={() => navigate(redirect.collectionsList({ collectionId: collection.collectionId }))}
          />
        ))}
      </Flex>
    </SectionHeader>
  );
};

export default Collections;
