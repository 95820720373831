const getStyles = (isMobile: boolean) =>  ({
  root: {
    gap: 32,
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: isMobile ? 'center' : 'flex-start',
  },
});
  
export default getStyles;
  